export function scrollObserver() {
    $.fn.inView = function(inViewType, offset = 0){

		// Calculates the viewport top and bottom position
		//  at the time of the call
		var viewport = {};
		viewport.top = $(window).scrollTop();
		viewport.bottom = viewport.top + $(window).height();

		// Calculate element bouds positions
		var bounds = {};
		bounds.top = this.offset().top + offset;
		bounds.bottom = bounds.top + this.outerHeight();

		switch(inViewType){
			case 'bottomOnly':
				return ((bounds.bottom <= viewport.bottom) && (bounds.bottom >= viewport.top));
			case 'topOnly':
				return ((bounds.top <= viewport.bottom) && (bounds.top >= viewport.top));
			case 'both':
				return ((bounds.top >= viewport.top) && (bounds.bottom <= viewport.bottom));
			default:
				return ((bounds.top >= viewport.top) && (bounds.bottom <= viewport.bottom));
		}
    };

    function checkVisability() {
        var observerElem = $('.observer_elem');
        var observerElemMobile = $('.observer_elem_mobile');
        var observerElemMobileBtns = $('.observer_elem_mobile_btns');
        var pageNavSticky = $('.page-nav-sticky');
        var pageNavStickyMobile = $('.page-nav-sticky_mobile');
        if (window.innerWidth > 768) {
            observerElem.each(function(){
                if ($(this).inView('both', -150)) {
                    pageNavSticky.each(function() {
                        $(this).removeClass("show-up");
                    });
                } else {
                    pageNavSticky.each(function() {
                        $(this).addClass("show-up");
                    });
                }
            });
        } else {
            observerElemMobile.each(function(){
                if ($(this).inView('both')) {
                    pageNavSticky.each(function() {
                        $(this).removeClass("show-up");
                    });
                } else {
                    pageNavSticky.each(function() {
                        $(this).addClass("show-up");
                    });
                }
            });
            observerElemMobileBtns.each(function(){
                if ($(this).inView('both')) {
                    pageNavStickyMobile.each(function() {
                        $(this).addClass("hidden");
                    });
                } else {
                    pageNavStickyMobile.each(function() {
                        $(this).removeClass("hidden");
                    });
                }
            });
        }
    }

    let prevScrollPos = window.pageYOffset;
    function stickyChange() {
        var pageNavSticky = $('.page-nav-sticky');
        const currentScrollPos = window.pageYOffset;

        if (prevScrollPos > currentScrollPos) {
            pageNavSticky.removeClass('sticky-hidden');
        } else {
            pageNavSticky.addClass('sticky-hidden');
        }

        prevScrollPos = currentScrollPos;
    }


    $(window).scroll(function() {
        checkVisability();
        stickyChange();
    });


    
}