export function accordion () {
    $('.accordion-item').click((e)=> {
        const arrayElems = Array.from(e.target.children);
        const findBodyElem = arrayElems.find((el) => el.className.includes('accordion-body'));
        const findHeaderElem = arrayElems.find((el) => el.className.includes('accordion-header'));
        if (findHeaderElem) {
            findHeaderElem?.classList.toggle('show')
        };
        if (findBodyElem) {
            if (findBodyElem.classList.contains('show')) {
                findBodyElem.style.maxHeight = null;
                findBodyElem.classList.remove('show');
            } else {
                findBodyElem.style.maxHeight = findBodyElem.scrollHeight * 2 + "px";
                findBodyElem.classList.add('show');
            }
        };

        const aboutUsAccordion = document.querySelectorAll('.about-us-faq__container__accordion-item');
        if(e.target.classList.contains('about-us-faq__container__accordion-item')) {
            aboutUsAccordion.forEach((el) => {
                const targetBody = e.target.querySelector('.accordion-body');
                const targetHeader = e.target.querySelector('.accordion-header');
                if(el !== e.target && el !== targetHeader && el !== targetBody) {
                    el.classList.remove('show');
                    el.style.maxHeight = null;
                }
            })
        }
    });
    
    const productCards = document.querySelectorAll('.product-accordion');
    
    productCards.forEach((el) => {
        el.addEventListener("mouseover", accordionHover, false);
        function accordionHover() {
            const findBtn = Array.from(el.children).find((btn) => btn.classList.contains('product-accordion__button'));
            findBtn.classList.add('active')
        }
    });

    productCards.forEach((el) => {
        el.addEventListener("mouseout", accordionUnHover, false);
        function accordionUnHover() {
            const findBtn = Array.from(el.children).find((btn) => btn.classList.contains('product-accordion__button'));
            findBtn.classList.remove('active')
        }
    });

    const orderReceipt = document.querySelectorAll('.order-receipt__title-block')

    orderReceipt.forEach((el) => {
        el.addEventListener('click', () => {
            const findInfo = Array.from(el.parentNode.children).find((el) => el.classList.contains('order-receipt__information'));
            el.classList.toggle('active');
            if (findInfo) {
                if (findInfo.classList.contains('show')) {
                    findInfo.style.maxHeight = null;
                    findInfo.classList.remove('show');
                } else {
                    findInfo.style.maxHeight = findInfo.scrollHeight * 2 + "px";
                    findInfo.classList.add('show');
                }
            };
        })
    })
    
}