// interactiveColor.js

import {changeHouseWallColor, changeHousePlinthColor} from "../paintingHome/script.js";
import {setTitle} from "../select.js";
import {appendPalletCard} from "./appendElements.js";

let globalPaletteId = 1;
let selectedElement = 'wall'; // По умолчанию выбрана стена

export function interactiveColor() {
    const wallButton = document.querySelectorAll('.wallButton');
    const plinthButton = document.querySelectorAll('.plinthButton');

    // Устанавливаем обработчики событий для кнопок
    wallButton.forEach(btn => {
        btn.addEventListener('click', () => {
            selectedElement = 'wall';
            plinthButton.forEach(btn => {
                btn.classList.remove('active')
            });
            wallButton.forEach(btn => {
                btn.classList.add('active')
            });
            materialsStateShown()
            getAllForQuery('Facade')
                .then(res => {
                    appendNewPallet(res)
                })
        });
    });

    plinthButton.forEach(btn => {
        btn.addEventListener('click', () => {
            selectedElement = 'plinth';
            wallButton.forEach(btn => {
                btn.classList.remove('active')
            });
            plinthButton.forEach(btn => {
                btn.classList.add('active')
            });
            materialsStateHidden();
            getAllForQuery('Foundation')
                .then(res => {
                    appendNewPallet(res)
                }).catch(error => {
                console.log(error);
            })
        });
    })

    const palletSelectors = Array.from(document.querySelector('fieldset[name="color"]').querySelectorAll('input[name="pallet"]'));

    palletSelectors.forEach(input => {
        input.addEventListener('click', event => {
            const palletId = event.target.value;
            globalPaletteId = palletId;
            getColors(palletId);
            setPalletData(palletId);
        });
    });


    const selectPallet = palletSelectors.find(input => input.checked);
    if (selectPallet) {
        setPalletData(selectPallet.value);
    }
}

export async function setColors(colors) {
    const container = document.querySelector('#palletColors');

    container.innerHTML = '';

    // Создание элемента label и input для каждого объекта цвета
    for (let i = 0; i < colors.length; i++) {
        const color = colors[i];

        const label = document.createElement('label');
        label.classList.add('painting-section__pallet-block__select-color-block__color-item');
        label.style.backgroundColor = color.code;

        const input = document.createElement('input');
        input.setAttribute('type', 'radio');
        input.setAttribute('name', 'palletColor');
        input.setAttribute('value', color.id);

        if (i === 0) {
            input.setAttribute('checked', 'checked');
            const shades = await getShades(color.id);
            await setShades(shades.shades);
        }

        label.appendChild(input);
        container.appendChild(label);

        label.addEventListener("click", async function (event) {
            const shades = await getShades(event.target.value);
            await setShades(shades.shades);
        });
    }
}

export async function setShades(shades) {
    const container = document.querySelector('#palletShades');

    container.innerHTML = '';

    for (let i = 0; i < shades.length; i++) {
        const shade = shades[i];

        const label = document.createElement('label');
        label.classList.add('painting-section__pallet-block__select-hue-block__hue-item');
        shade.texture ? label.style.backgroundImage = `url("${shade.texture}")` : label.style.backgroundColor = shade.code_hex;


        const input = document.createElement('input');
        input.setAttribute('id', `hue${shade.id}`);
        input.setAttribute('type', 'radio');
        input.setAttribute('name', 'palletHue');
        input.setAttribute('value', shade.code_hex); // Добавление hex в значение атрибута value
        if (shade.texture) input.classList.add('texture');

        if (i === 0) {
            label.classList.add('selected')
            input.setAttribute('checked', 'checked');
            setTimeout(() => {
                if (selectedElement === 'wall') {
                    changeHouseWallColor(shade.code_hex);
                } else if (selectedElement === 'plinth') {
                    changeHousePlinthColor(shade.texture);
                }
            }, 100);
        }

        label.appendChild(input);

        const span = document.createElement('span');
        span.classList.add('painting-section__pallet-block__select-hue-block__hue-item__name');
        span.textContent = shade.name;
        label.appendChild(span);

        container.appendChild(label);

        let isClick = false;
        label.addEventListener("click", function (event) {
            isClick = !isClick;

            if (isClick) {

                document.querySelector('.painting-section__pallet-block__select-hue-block__hue-item.selected').classList.remove('selected')

                event.target.closest('.painting-section__pallet-block__select-hue-block__hue-item').classList.add('selected')

                if (selectedElement === 'wall') {
                    changeHouseWallColor(shade.code_hex);
                } else if (selectedElement === 'plinth') {
                    changeHousePlinthColor(shade.texture);
                }
            }
        });
    }
    ;

    const notLoadedElem = document.querySelector('.painting-section__not-loaded');
    if (notLoadedElem) {
        setTimeout(() => {
            const wallButton = document.querySelector('.wallButton');
            wallButton.click()
            notLoadedElem.parentElement.removeChild(notLoadedElem)
        }, 1000);
    }
}

export async function setPalletData(palletId) {
    const palletData = await getColors(palletId);
    // const paintPriceBlock = document.querySelector('#paintPrice');
    setColors(palletData.colors);
    // paintPriceBlock.innerText = `Стоимость за литр краски: ${palletData.price} ₽`;
    sessionStorage.setItem('paintPrice', palletData.price);
    calculateTotalPrice();
}

export async function calculateTotalPrice() {
    // const square = document.querySelector('.square').value ? document.querySelector('.square').value : 0;

    // const paintTotalPriceBlock = document.querySelector('#paintTotalPrice');
    // const price = await getPalletPrice(globalPaletteId, square);
    // if(paintTotalPriceBlock) paintTotalPriceBlock.innerText = `${price.price} литров`;
}

async function getAllForQuery(query) {
    const response = await fetch(
        `/api/pallets/` + "?type=" + query,
        {
            "method": "GET"
        }
    );
    if (response.ok) return response.json();
    else throw new Error(response.statusText);
}


export async function getColors(palletId) {
    const response = await fetch(
        `/api/pallets/${palletId}/`,
        {
            "method": "GET"
        }
    );
    if (response.ok) return response.json();
    else throw new Error(response.statusText);
}

async function getShades(colorId) {
    const response = await fetch(
        `/api/colors/${colorId}/`,
        {
            "method": "GET"
        }
    );
    if (response.ok) return response.json();
    else throw new Error(response.statusText);
}


function appendNewPallet(res) {
    const selectContent = document.querySelector('.palleteColorsSelect');
    let paletteFragment = new DocumentFragment();

    res.results.forEach(palette => {
        const [input, label] = appendPalletCard(palette);
        paletteFragment.appendChild(input);
        paletteFragment.appendChild(label);
    });

    Array.from(selectContent.children).forEach(child => {
        child.parentElement.removeChild(child)
    });
    selectContent.appendChild(paletteFragment);

    const inputChecked = selectContent.querySelector('input');
    const selectSingle_title = document.querySelector('.__select__title');

    let defaultText = '';

    if (inputChecked) {
        inputChecked.setAttribute.state = 'checked';
        defaultText = inputChecked.getAttribute('data-name');
        inputChecked.click();
    }

    if (selectSingle_title) setTitle(selectSingle_title, defaultText);
}

function materialsStateHidden() {
    const materials = document.querySelectorAll('.materialsToChoose');
    materials.forEach(material => {
        material.style.opacity = '0';
        material.style.scale = '0';
        setTimeout(() => {
            material.style.display = 'none';
        }, 100);
    })
}

function materialsStateShown() {
    const materials = document.querySelectorAll('.materialsToChoose');
    materials.forEach(material => {
        material.style.display = 'flex';
        setTimeout(() => {
            material.style.opacity = '1';
            material.style.scale = '1';
        }, 100);
    })
}