export function loader() {
    const productLoader = document.querySelector('.product-card-section__info-block_loader');
    const productInfoBlock = document.querySelector('.product-card-section__info-block');
    if(productLoader) {
        setTimeout(() => {
            productLoader.parentElement.removeChild(productLoader);
        }, 1500);
    };
    const modificationBtn = document.querySelectorAll('.modification_product');
    if(modificationBtn) {
        modificationBtn.forEach((btn) => {
            btn.addEventListener('click',() => {
                const loader = document.createElement('div');
                loader.classList.add('product-card-section__info-block_loader');
                loader.innerHTML = `
                    <div class="product-card-section__info-block_loader__loading-elem">
                        <div></div>
                    </div>
                    <div class="product-card-section__info-block_loader__title-block">
                        <div class="not-loaded-bg not-loaded-content"></div>
                    </div>
                    <div class="product-card-section__info-block_loader__info-block">
                        <div class="not-loaded-bg not-loaded-content"></div>
                        <div class="not-loaded-bg not-loaded-content"></div>
                        <div class="not-loaded-bg not-loaded-content"></div>
                        <div class="not-loaded-bg not-loaded-content"></div>
                        <div class="not-loaded-bg not-loaded-content"></div>
                        <div class="not-loaded-bg not-loaded-content"></div>
                        <div class="not-loaded-bg not-loaded-content"></div>
                        <div class="not-loaded-bg not-loaded-content"></div>
                        <div class="not-loaded-bg not-loaded-content"></div>
                    </div>
                `
                productInfoBlock.appendChild(loader);
            });
        });
    };
}