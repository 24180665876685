import { debounce } from "./utils.js";

export function map() {
    const mapMain = document.getElementById('map');
    const mapMobile = document.getElementById('map_mobile');
    if (mapMain) {
        ymaps.ready(init);
        var myMap;

        function init() {
            myMap = new ymaps.Map("map", {
                center: [55.76, 37.64], 
                zoom: 6,
                controls: []
            });
        }
    };
    if (mapMobile) {
        ymaps.ready(init);
        var myMapMobile;

        function init() {
            myMapMobile = new ymaps.Map("map_mobile", {
                center: [55.76, 37.64],
                zoom: 5,
                controls: [],

                behaviors: ['default', 'scrollZoom']
            });
        }
    };
    const mapShown = document.getElementById('map_shown');
    const showMapBtn = document.getElementById('show_map_btn');
    const closeMapBtns = document.querySelectorAll('.close_map_btn');
    const addressMapInput = document.getElementById('address_map_input');
    const addressInput = document.getElementById('address');
    if(showMapBtn) {
        showMapBtn.addEventListener('click',() => {
            mapShown.style.display = 'grid';
            document.documentElement.style.overflow = "hidden";
        });
    };

    const dropDownList = document.querySelector('.input-dropdown__list');
    const secondForm = document.querySelector('.basket-map-section__form_second');

    const deliveryHereBtn = document.getElementById('delivery_here_btn');
    const deliveryClearBtn = document.getElementById('delivery_clear_btn');
    const privateHouse = document.getElementById('privateHouse');

    if(addressMapInput) {
        addressMapInput.addEventListener('input',() => {
            (addressMapInput.value == '') ? dropDownList.style.display = 'none' : dropDownList.style.display = 'flex';
            const fragment = document.createDocumentFragment();
            sendText('https://suggest-maps.yandex.ru/v1/suggest?apikey=6113898e-6805-4af0-a178-94df8a308a44', addressMapInput.value)
            .then(data => {
                if(data['results']) {
                    data['results'].forEach((result) => {
                        const span = document.createElement('div');
                        span.classList.add('input-dropdown__list__span');
                        if(result['subtitle']) {
                            span.innerHTML = result['subtitle'].text + ' ' + result['title'].text;
                        } else if(result['title']) {
                            span.innerHTML = result['title'].text;
                        };
                        fragment.appendChild(span);
                        // dropDownList.appendChild(span);
                        span.addEventListener('click',() => {
                            addressMapInput.value = span.innerText;
                            dropDownList.style.display = 'none';
                            secondForm.style.display = 'grid';
                            deliveryHereBtn.classList.remove('button_accent_disabled');
                            
                            let firstCoordinate = '';
                            let secondCoordinate = '';
                            let myGeocoder = ymaps.geocode(addressMapInput.value)
                            .then(
                                function(res) {
                                    firstCoordinate = res.geoObjects.get(0).geometry.getCoordinates()['0'];
                                    secondCoordinate = res.geoObjects.get(0).geometry.getCoordinates()['1'];
                                    Array.from(mapMain.children).forEach((el) => {
                                        mapMain.removeChild(el)
                                    })
                                    Array.from(mapMobile.children).forEach((el) => {
                                        mapMobile.removeChild(el)
                                    })

                                    if (window.innerWidth > 768) {
                                        ymaps.ready(init);
                                        var myMap;
            
                                        function init() {
                                            myMap = new ymaps.Map("map", {
                                                center: [Number(firstCoordinate), Number(secondCoordinate)], 
                                                zoom: 15,
                                                controls: [],
                                            });
                                            setTimeout(() => {
                                                let placemark = new ymaps.Placemark([Number(firstCoordinate), Number(secondCoordinate)], {}, {
                                                    iconLayout: "default#image",
                                                    iconImageHref: '/static/assets/images/icons/map_pin.svg',
                                                    iconImageSize: [49, 30],
                                                    iconImageOffset: [-24.5, -30],
                                                })
                                
                                                myMap.geoObjects.add(placemark)
                                            }, 50);
                                        }
                                    } else {
                                        ymaps.ready(init);
                                        var myMapMobile;
                                
                                        function init() {
                                            myMapMobile = new ymaps.Map("map_mobile", {
                                                center: [Number(firstCoordinate), Number(secondCoordinate)],
                                                zoom: 15,
                                                controls: [],
                                            });
                                            setTimeout(() => {
                                                let placemark = new ymaps.Placemark([Number(firstCoordinate), Number(secondCoordinate)], {}, {
                                                    iconLayout: "default#image",
                                                    iconImageHref: '/static/assets/images/icons/map_pin.svg',
                                                    iconImageSize: [49, 30],
                                                    iconImageOffset: [-24.5, -30]
                                                })
                                
                                                myMapMobile.geoObjects.add(placemark)
                                            }, 50);
                                        }
                                    }
                                }
                            )

                            
                        });
                    });
                    dropDownList.innerHTML = '';
                    dropDownList.appendChild(fragment);
                };
            });
            if(secondForm.style.display == 'grid') {
                secondForm.style.display = 'none';
                deliveryHereBtn.classList.add('button_accent_disabled');
                secondForm.querySelectorAll('input').forEach((input) => {
                    input.value = '';
                });
                secondForm.querySelector('textarea').value = '';
            }
        });
        privateHouse.addEventListener('click',() => {
            if(privateHouse.checked) {
                secondForm.querySelectorAll('input').forEach((input) => {
                    input.value = '';
                    input.parentElement.style.display = 'none';
                });
            } else {
                secondForm.querySelectorAll('input').forEach((input) => {
                    input.value = '';
                    input.parentElement.style.display = 'flex';
                });
            }
        });
        deliveryClearBtn.addEventListener('click',() => {
            mapShown.style.display = 'none';
            document.documentElement.style.overflow = '';
            addressMapInput.value = '';
            Array.from(dropDownList.children).forEach((el) => {
                dropDownList.removeChild(el)
            })
            secondForm.querySelectorAll('input').forEach((input) => {
                input.value = '';
            });
            deliveryHereBtn.classList.add('button_accent_disabled');
            secondForm.querySelector('textarea').value = '';
            secondForm.style.display = 'none';
            dropDownList.style.display = 'none';
        })
    }

    if(closeMapBtns) {
        closeMapBtns.forEach((btn) => {
            btn.addEventListener('click',() => {
                mapShown.style.display = 'none';
                document.documentElement.style.overflow = '';
            });
        });
    };

    if(deliveryHereBtn) {
        deliveryHereBtn.addEventListener('click',() => {
            const infoBlock = document.querySelector('.basket-map-section__info-block');

            let fullAddress = addressMapInput.value;
            infoBlock.querySelectorAll('input').forEach((input) => {
                if(input.id == 'floor' && input.value !== '') {
                    fullAddress = fullAddress + ' этаж ' + input.value
                }
                if(input.id == 'apartment' && input.value !== '') {
                    fullAddress =  fullAddress + ' кв. ' + input.value
                }
            });

            let event = new Event('input', { bubbles: true });
            addressInput.value = fullAddress;
            addressInput.dispatchEvent(event);

            if(addressInput.value !== '') addressInput.parentElement.classList.remove('error');
            const inputs = document.querySelectorAll('input');
            let flag = true;
            if(inputs.length > 0) {
                inputs.forEach(input=> {
                    if(input.parentElement.classList.contains('error')) flag = false;
                });
            }

            const makeOrderBtn = document.getElementById('make-order-btn');
            if(flag) if(makeOrderBtn) makeOrderBtn.classList.remove('button_accent_disabled');

            mapShown.style.display = 'none';
            document.documentElement.style.overflow = '';
        });
    };


}

async function sendText(url, text) {
    const response = await fetch(url + '&text=' + text,{
        method: "GET",
    })
    if(response.ok) return response.json();
    else throw new Error(response.statusText);
};
