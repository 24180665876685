import { addQuery } from "./utils.js";


export function calendar() {
    new AirDatepicker('#data_orders', {
        dateFormat: 'dd.MM.yyyy',
        maxDate: new Date(),
        dateDelimiter: '.',
        autoClose: true,
        onSelect: function (formattedDate) {
            const val = formattedDate.formattedDate
            addQuery(['data_create'],[val])
        }
    });

    new AirDatepicker('#date_delivery', {
        dateFormat: 'dd.MM.yyyy',
        minDate: new Date(),
        autoClose: true,
    });

    function formatDate(input) {
        let cleaned = input.value.replace(/\D/g, '');
        let formatted = '';
        for (let i = 0; i < cleaned.length; i++) {
            if (i == 2 || i == 4) {
                formatted += '.';
            }
            formatted += cleaned[i];
        }
        if (formatted.length > 10) {
            formatted = formatted.substr(0, 10);
        }
        input.value = formatted;
    };

    const dataInput = document.getElementById('data_orders');

    if(dataInput) {
        if (dataInput) {
            dataInput.addEventListener('input', function() {
                formatDate(dataInput);
            });
        }
    }
}