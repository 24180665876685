import { changeBasketWithValue } from "../../basket.js";
import { addToBasket, addToWishlist, basketChangeFetch, getCookieIndividual, getWishlistFromCookie, removeFromBasket, removeFromWishlist, userLoggedIn, wishlistChangeFetch } from "../../cookies.js";
import { noPaginationBlock } from "../../pagination.js";
import { debounce } from "../../utils.js";

export function AddCatalogCards(data,list) {
    const fragment = document.createDocumentFragment();
    data.results.forEach((el) => {
        const newCard = addCard(el);

        const addBtns = newCard.querySelectorAll('.add_to_basket');
        const changeBtns = newCard.querySelectorAll('.change_counter_value');
        const wishlistBtns = newCard.querySelectorAll('.add_to_wishlist');

        addInbasket(addBtns);
        changeBasket(changeBtns);
        wishlistChange(wishlistBtns);
        cardAccordion(newCard);
        changeBtns.forEach(btn=>{
            const input = btn.querySelector('input',()=>{
                input.value = input.value.replace(/\D/g, '');
                addBtns.forEach(add=>{
                    debouncedChangeWithValue(add);
                });
            });
        });
        fragment.appendChild(newCard);
    })
    list.appendChild(fragment);
    if(!data.next) noPaginationBlock();
}


function addCard(el) {

    let inBasketAlready = false;
    let inWishlistAlready = false;
    if(userLoggedIn()) {
        if(el['in_basket']) inBasketAlready = true;
        if(el['in_wishlist']) inWishlistAlready = true;
    } else {
        getWishlistFromCookie().forEach((cookie) => {
            if(cookie == el['id']) inWishlistAlready = true;
        })
        getCookieIndividual().forEach((obj) => {
            if(obj['product'] == el['id']) inBasketAlready = true;
        });
    };

    const newCard = document.createElement('div');
    newCard.classList.add('product-card', 'product-card_size_basic', 'product-accordion');
    let dataImage = el['main_photo'] ?  el['main_photo'] : '/static/assets/images/img/stub.png';

    newCard.innerHTML = `
    <div class="product-card_size_basic__info-block">
        <div class="product-card_size_basic__img-block">
            <a href="/product/${el['slug']}"
            class="product-card_size_basic__img-block__img">
                <img loading="lazy" src="${dataImage}">
            </a>
            <div class="product-card_size_basic__badge-block">
                ${el['profitably'] ? `
                    <div class="badge badge_category_small badge_accent">
                        Выгодно!
                    </div>
                ` : ''}
                ${el['popular'] ? `
                    <div class="badge badge_category_small badge_terteriary">
                        Популярное
                    </div>
                ` : ''}
            </div>
            <button
                class="button button_icon_large add_to_wishlist
                    ${inWishlistAlready ? `
                        in-basket
                    ` : ''}
                "
            >
                <input type="hidden" value="${el['id']}">
                <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M16.4909 6.84775C13.8251 3.7312 9.37965 2.89286 6.03958 5.74668C2.69952 8.60051 2.22928 13.372 4.85225 16.7472C7.03308 19.5535 13.633 25.4721 15.7961 27.3878C16.0381 27.6021 16.1591 27.7093 16.3003 27.7514C16.4235 27.7881 16.5583 27.7881 16.6814 27.7514C16.8226 27.7093 16.9436 27.6021 17.1856 27.3878C19.3487 25.4721 25.9486 19.5535 28.1295 16.7472C30.7524 13.372 30.3396 8.57049 26.9421 5.74668C23.5447 2.92288 19.1567 3.7312 16.4909 6.84775Z"
                        stroke="#EE7F01" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
        <a href="/product/${el['slug']}" class="product-card_size_basic__title-block">
            <div class="product-card_size_basic__cost-block">
                <div class="product-card_size_basic__cost-block__cost">
                    ${el['price']} ₽
                </div>
                ${el['discount'] ? `
                    <div class="product-card_size_basic__cost-block__cost_prev">
                        ${el['basic_price']} ₽
                    </div>
                ` : ''}
            </div>
            <div class="product-card_size_basic__title-block__title">
                ${el['name']}
            </div>
        </a>
    </div>

    <div class="product-accordion__button">
        <button class="button button_accent button_size_small add_to_basket
            ${inBasketAlready ? `
                in-basket
            ` : ''}
        ">
            В корзину
            <input type="hidden" value="${el['id']}">
        </button>

        <button class="button button_counter_basic">
            <svg class="change_counter_value minus" width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M5 12H19" stroke="#1C1917" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <input type="text" value="{{ basket_product.quantity }}">
            <svg class="change_counter_value plus" width="24" height="24" viewBox="0 0 24 24" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5V19M5 12H19" stroke="#1C1917" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
        </button>

    </div>
    `;

    return newCard;
   
}


function cardAccordion(card) {
    card.addEventListener("mouseover", accordionHover, false);
    function accordionHover() {
        const findBtn = Array.from(card.children).find((btn) => btn.classList.contains('product-accordion__button'));
        findBtn.classList.add('active')
    }
    card.addEventListener("mouseout", accordionUnHover, false);
    function accordionUnHover() {
        const findBtn = Array.from(card.children).find((btn) => btn.classList.contains('product-accordion__button'));
        findBtn.classList.remove('active')
    }
};

function addInbasket(btns) {
    btns.forEach((el) => {
        el.addEventListener('click',() => {
            const parent = el.parentNode;
            const id = el.querySelector('input').value;
            const parsedId = parseInt(id)
            const input = Array.from(parent.querySelector('.button_counter_basic').children).find(el => el.localName == 'input')
            input.value = 1;
            el.classList.add('in-basket');
            if(userLoggedIn()) {
                let producObj = {};
                producObj['product'] = parsedId;
                producObj['quantity'] = input.value;
                basketChangeFetch(producObj, 'add/', userLoggedIn());
            } else {
                removeFromBasket(id);
                addToBasket(id, 1);
            }
        });
    });
};

function changeBasket(btns) {
    btns.forEach((btn) => {
        const findInput = Array.from(btn.parentNode.children).find(el => el.localName == 'input');
        const findAddBtn = Array.from(btn.parentNode.parentNode.children).find(el => el.classList.contains('add_to_basket'));
        const findId = Array.from(findAddBtn.children).find(el => el.localName == 'input').value;
        if(userLoggedIn()) {

        } else {
            Array.from(getCookieIndividual())
            .forEach((el) => {
                if(el.product == findId) {
                    findAddBtn.classList.add('in-basket');
                    findInput.value = el.quantity;
                }
            })
        }
        btn.addEventListener('click',() => {
            let inputVal = parseInt(findInput.value);
            if (btn.classList.contains('plus')) {
                inputVal += 1;
                if (userLoggedIn()) {
                    const parsedId = parseInt(findId);
                    let producObj = {};
                    if(parsedId) {
                        producObj['product'] = parsedId;
                    }
                    
                    basketChangeFetch(producObj, 'add/', userLoggedIn());
                }
            }
            else if (btn.classList.contains('minus')) {
                inputVal -= 1;
                if(userLoggedIn()) {
                    if (inputVal <= -1) {
                        const parsedId = parseInt(findId);
                        let producObj = {};
                        if(parsedId) {
                            producObj['product'] = parsedId;
                        }
                        basketChangeFetch(producObj, 'remove/', userLoggedIn());
                    } else {
                        const parsedId = parseInt(findId);
                        let producObj = {};
                        if(parsedId) {
                            producObj['product'] = parsedId;
                        }

                        basketChangeFetch(producObj, 'decrement/', userLoggedIn());
                    }
                }
            }
            if (userLoggedIn()) {

            } else {
                
                removeFromBasket(findId);
            }
            findInput.value = inputVal >= 0 ? inputVal : 0;
            if (inputVal <= 0) {
                findAddBtn.classList.remove('in-basket');
            } else {
                if (userLoggedIn()) {
                    
                } else {
                    
                    addToBasket(findId, findInput.value);
                }
            }
        });
    });
};

function wishlistChange(btns) {
    btns.forEach((btn) => {
        const findIdVal = btn.querySelector('input').value;
        if(!userLoggedIn()) {
            let wishlist = getWishlistFromCookie();
            if(wishlist.includes(findIdVal)) btn.classList.add('favourited');
        }
        btn.addEventListener('click', () => {
            if (btn.classList.contains('favourited')) {
                btn.classList.remove('favourited');
                if(userLoggedIn()) {
                    wishlistChangeFetch(findIdVal, 'remove/', userLoggedIn());
                } else {
                    removeFromWishlist(findIdVal);
                }
                
            } else {
                btn.classList.add('favourited');
                if(userLoggedIn()) {
                    wishlistChangeFetch(findIdVal, 'add/', userLoggedIn());
                } else {
                    addToWishlist(findIdVal);
                }
            };
        });
    });
};

const debouncedChangeWithValue = debounce(changeBasketWithValue, 500);