import * as THREE from 'three';
import {OrbitControls} from 'three/addons/controls/OrbitControls.js';

const init = () => {

    const container = document.querySelector('#houseContainer');

    const sizes = {
        width: container.clientWidth,
        height: container.clientHeight,
    };

    const scene = new THREE.Scene();
    const canvas = document.querySelector('#house');
    const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 1000);
    scene.add(camera);

    const controls = new OrbitControls(camera, canvas);
    controls.enableDamping = true;

    controls.enableRotate = true; // включаем возможность вращения
    controls.enableZoom = true;

    controls.minDistance = 5; // Минимальное расстояние камеры
controls.maxDistance = 20; // Максимальное расстояние камеры

    controls.enableFullscreen = false; // Отключаем возможность полноэкранного режима

    controls.minPolarAngle = Math.PI / 2; // Минимальный угол вращения по оси Y (прямо вверх)
    controls.maxPolarAngle = Math.PI / 2; // Максимальный угол вращения по оси Y (прямо вниз)
    controls.minAzimuthAngle = -Infinity; // Минимальный угол вращения по оси X (бесконечность)
    controls.maxAzimuthAngle = Infinity; // Максимальный угол вращения по оси X (бесконечность)

    const renderer = new THREE.WebGLRenderer({canvas, alpha: true, antialias: true});
    renderer.setSize(sizes.width, sizes.height);
    renderer.render(scene, camera);
    container.appendChild(renderer.domElement);

    const mouse = new THREE.Vector2();

    return {sizes, scene, container, canvas, camera, renderer, controls, mouse};
};

export default init;
