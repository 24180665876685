
export function addQuery(key, values) {
    const url = new URLSearchParams(window.location.search);

    if(Array.isArray(key)) {
        key.forEach((el,index)=>{
            url.delete(el);
            if(Array.isArray(values)) {
                const value = values[index];
                if(Array.isArray(value)) {
                    value.forEach(elem=>{
                        if(elem != null) url.append(el,elem);
                    });
                } else if(!!value) url.append(el,value);
            }
        });
      
    } else {
        url.delete(key);
        if(Array.isArray(values)) {
            values.forEach(value => {
                url.append(key, value);
            });
        } else url.append(key,`${values}`);
    }
   
    window.location.search = url.toString();
};
export function debounce(func, timeout) {
    let timer;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function() {
            func.apply(context, args);
        }, timeout);
    };
};

export function findParentWithClass(element, className) {
    let parent = element.parentElement;

    while (parent) {
        if (parent.classList.contains(className)) {
            return parent;
        }
        parent = parent.parentElement;
    }

    return null; // Если родительский элемент с указанным классом не найден
}

// Проверка наличия элемента в массиве
// для избежания дублирования фильтров
export function addOrRemoveQuery(arr, value) {
    let index = arr.indexOf(value);
    if (index === -1) {
        arr.push(value);
    }
    else {
        arr.splice(index, 1);
    }
};

const main = document.querySelector('main');
const headerContainer = document.querySelector('.header-section__container');
const stickyHeader = document.getElementById('sticky-header');
export function docOverflowHidden() {
    document.documentElement.style.overflow = "hidden";
    if (document.location.pathname !== "/") {
        main.style.paddingTop = headerContainer.clientHeight + 'px';
    }
    stickyHeader.style.position = "fixed";
    stickyHeader.classList.remove('sticky-hidden');
    if(window.innerWidth > 769) {
        document.documentElement.style.marginRight = '.375rem';
    }
}
export function docOverflowRemove() {
    document.documentElement.style.removeProperty('overflow');
    main.style.padding = '';
    if(stickyHeader) stickyHeader.style.position = "sticky";
    document.documentElement.style.margin = '';
}
export function docOverflowRemoveTimeout() {
    document.documentElement.style.removeProperty('overflow');
    setTimeout(() => {
        main.style.padding = '';
        if(stickyHeader) stickyHeader.style.position = "sticky";
    }, 300);
    document.documentElement.style.margin = '';
}
