import { addQuery } from "./utils.js";

export function sort() {
    const sorterElem = document.querySelectorAll('.sort__element');
    const sorterElemAll = document.querySelectorAll('.sort__element_all');

    const changeSortName = document.querySelectorAll('.change-sort-name'); 
    
    if (sorterElem.length>0) {
        sorterElem.forEach((el) => {
            const sortValue = el.getAttribute('data-sort');
            const typeValue = el.getAttribute('data-type');
            const tagValue = el.getAttribute('data-tag');
            if(sortValue == changeName(el)) changeSortName.forEach(elem => elem.innerText = el.innerText);
            if(typeValue) {
                const searchParams = new URLSearchParams(window.location.search);
                const typeQueryAll = searchParams.getAll('type');
                typeQueryAll.forEach(query=> {
                    if(query == typeValue) el.classList.add('button_text_basic_accent')
                })
            }
            if(tagValue) {
                const searchParams = new URLSearchParams(window.location.search);
                const tagQueryAll = searchParams.getAll('tag');
                tagQueryAll.forEach(query=> {
                    if(query == tagValue) el.classList.add('button_text_basic_accent')
                })
            }
            el.addEventListener('click',() => {
                if (sortValue) {
                    addQuery('sort', sortValue);
                }
                if (typeValue) {
                    addQuery('type', typeValue);
                }
                if (tagValue) {
                    addQuery('tag', tagValue);
                }
            })
        });
        sorterElemAll.forEach((el) => {
            el.addEventListener('click',() => {
                const paramsString = window.location.search;
                const searchParams = new URLSearchParams(paramsString);
                searchParams.delete('sort');
                searchParams.delete('type');
                searchParams.delete('tag');
                window.location.search = searchParams.toString();
            })
        })
    }

}

function changeName() {
    let queryValue = '';
    const queryParams = new URLSearchParams(window.location.search);
    for (let pair of queryParams.entries()) {
        let key = pair[0];
        let value = pair[1];
        if(key == 'sort') {
            queryValue = value;
        };
    };
    // changeSortName.innerText = el.innerText;
    return queryValue;
}