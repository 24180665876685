import {cookieAcceptation} from "./modules/cookies.js";
import {wishlist} from "./modules/wishlist.js";
import {basket} from "./modules/basket.js";
import {logout} from "./modules/logout.js";
import {accordion} from "./modules/accordion.js";
import {calendar} from "./modules/calendar.js";
import {header} from "./modules/header.js";
import {attachFile} from "./modules/attach-file.js";

import {validation} from "./modules/validation.js";
import {inputs} from "./modules/inputs.js";
import {order} from "./modules/order.js";
import {globalSearcher} from "./modules/globalSearcher.js";
import {filters} from "./modules/filters.js";
import {sort} from "./modules/sort.js";
import {pagination} from "./modules/pagination.js";
import {modals} from "./modules/modals.js";
import {sliders} from "./modules/sliders.js";

import {anchor} from "./modules/anchor.js";
import {prevLocation} from "./modules/prevLocation.js";
import {scrollObserver} from "./modules/scrollObserver.js";
import {turkneyCard} from "./modules/turkneyCard.js";

import {colorMaterial} from "./modules/colorMaterial.js";
import {showMore} from "./modules/showMore.js";


import {technicalPage} from "./modules/technicalPage.js";
import {map} from "./modules/map.js";
import {paintingHome} from "./modules/paintingHome/script.js";
import {interactiveColor} from "./modules/interactiveColor/script.js";
import {select} from "./modules/select.js";
import * as pages from './modules/pages/index.js';
import {productToggle} from "./modules/productToggle.js";
import {palette} from "./modules/palette.js";


document.addEventListener('DOMContentLoaded', () => {
    const houseContainer = document.querySelector('#houseContainer');

    if (houseContainer) {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Элемент в области видимости, загружаем модули
                    import('./modules/paintingHome/script.js')
                        .then(module => {
                            const paintingHome = module.paintingHome;
                            return paintingHome();
                        })
                        .then(() => {
                            return import('./modules/interactiveColor/script.js');
                        })
                        .then(module => {
                            const interactiveColor = module.interactiveColor;
                            interactiveColor();
                        })
                        .catch(error => {
                            console.error('Ошибка при загрузке модулей:', error);
                        });
                    observer.disconnect();
                }
            });
        });

        observer.observe(houseContainer);
    }
});


cookieAcceptation()
wishlist()
basket()
logout()
accordion()
calendar()
header()
attachFile()

validation()
inputs()
order()
globalSearcher()
filters()
sort()
pagination()
modals()
sliders()

anchor()
prevLocation()
scrollObserver()
turkneyCard()

colorMaterial()
showMore()

technicalPage()
map()


select()
productToggle()

palette()