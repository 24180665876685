import {addOrRemoveQuery, addQuery, debounce} from "./utils.js";

// Передача query параметров в url
const debouncedAddQuerySearch = debounce(addQuery, 1500);

export function filters() {

    // Открытие мобильного меню фильтров

    const openMobileFilters = document.getElementById('open__filters__mobile');
    const mobileFilters = document.getElementById('filters__mobile');

    if (openMobileFilters) {
        openMobileFilters.addEventListener('click', (e) => {
            mobileFilters.classList.add('active');
            document.documentElement.style.overflow = "hidden";
        })
    }


    // Фильтрация на странице заказов

    const orderInput = document.getElementById('search_orders');
    const dataInput = document.getElementById('data_orders');


    if (orderInput) {

        const queryParams = new URLSearchParams(window.location.search);
        for (let pair of queryParams.entries()) {
            let key = pair[0];
            let value = pair[1];
            if (key == 'order_number') {
                orderInput.value = value;
            }
            ;
            if (key == 'data_create') {
                dataInput.value = value;
            }
            ;
        }
        ;

        let orderVal = [];
        let dataVal = [];

        orderInput.addEventListener('input', () => {
            orderVal = []
            orderVal.push(orderInput.value);
            debouncedAddQuerySearch(['order_number'], [orderVal.slice()]);
        });

        dataInput.addEventListener('input', () => {
            dataVal = []
            dataVal.push(dataInput.value)
            debouncedAddQuerySearch(['data_create'], [dataVal.slice()]);
        })
    }
    ;

    // Фильтрация на странице гововых решений

    const readySolutionsNavigation = document.querySelector('.ready-solutions-section__fast-navigation');

    if(readySolutionsNavigation) {
        getSolutions('/api/product-packages/solutions/')
            .then(data=>{
                const solutions = data.solutions;
                const fragment = document.createDocumentFragment();
                solutions.forEach(solution=>{
                    const button = document.createElement('button');
                    button.classList.add('button', 'button_text_basic');
                    button.setAttribute('data-page', `${solution.page}`);
                    button.innerText = solution.title;

                    button.addEventListener('click',()=>{
                        const page = button.getAttribute('data-page');
                        addQuery('page', page);
                    });

                    fragment.appendChild(button);
                });
                readySolutionsNavigation.appendChild(fragment)
            });
    }

    // Фильтрация на технической странице готовых решений

    const inputSolutions = document.getElementById('input__search__solutions');

    let solutionsVal = [];
    let filterSolutionsVal = [];

    if (inputSolutions) {
        const queryParams = new URLSearchParams(window.location.search);
        for (let pair of queryParams.entries()) {
            let key = pair[0];
            let value = pair[1];
            if (key == 'q') {
                inputSolutions.value = value;
            }
            ;
        }
        ;

        inputSolutions.addEventListener('input', () => {
            solutionsVal = []
            solutionsVal.push(inputSolutions.value);
            debouncedAddQuerySearch(['q'], [solutionsVal.slice()]);
        });
    }
    ;

    const filtersSolutions = document.querySelectorAll('.technical-section__filter');
    const filterAllSolutions = document.querySelector('.technical-section__filter_all');
    if (filtersSolutions.length > 0) {

        const queryParams = new URLSearchParams(window.location.search);
        for (let pair of queryParams.entries()) {
            let key = pair[0];
            let value = pair[1];
            if (key == 'solutions') {
                filterSolutionsVal.push(value)
            }
            ;
        }
        ;

        (filterSolutionsVal.length > 0) ? filterAllSolutions.classList.remove('active') : filterAllSolutions.classList.add('active');

        filtersSolutions.forEach((el) => {
            const dataFilter = el.getAttribute('data-filter');
            if (filterSolutionsVal.includes(dataFilter)) {
                el.classList.add('active')
            }
            el.addEventListener('click', () => {
                (filterSolutionsVal.length > 0) ? filterAllSolutions.classList.remove('active') : filterAllSolutions.classList.add('active');
                (el.classList.contains('active')) ? el.classList.remove('active') : el.classList.add('active');
                addOrRemoveQuery(filterSolutionsVal, dataFilter);
                debouncedAddQuerySearch(['q'], [filterSolutionsVal.slice()]);
            })
        });

        filterAllSolutions.addEventListener('click', () => {
            filterSolutionsVal = [];
            addQuery(['q'], [filterSolutionsVal.slice()])
        })

    }
    ;


    // Полная очистка фильтров

    const clearBtn = document.querySelectorAll('.clear__query');

    if (clearBtn.length > 0) {
        clearBtn.forEach((btn) => {
            btn.addEventListener('click', () => {
                clearQueryParamsFromURL()
                window.location.reload()
            })
        });
    }
    ;

    function clearQueryParamsFromURL() {
        const url = new URL(window.location.href);
        url.search = '';
        window.history.replaceState({}, document.title, url.href);
    }

}


async function getSolutions(url) {
    const response = await fetch(url,{
        method: "GET",
    })
    if(response.ok) return response.json();
    else throw new Error(response.statusText);
};


export function BrandPricesFilter() {
    const filterElems = document.querySelectorAll('.filter__element');
    const filterAllElems = document.querySelectorAll('.filter__element_all');

    const filterConfirmMobile = document.getElementById('filter__confirm_mobile');
    const filterDenyMobile = document.getElementById('filter__deny_mobile');

    const inputMinPrice = document.querySelectorAll('.filter__input__min_price');
    const inputMaxPrice = document.querySelectorAll('.filter__input__max_price');


    let filter = []
    let brand = []
    let category = []
    let min_price = []
    let max_price = []

    if (filterElems.length > 0) {

        // Проверка применённых к странице фильтров
        const queryParams = new URLSearchParams(window.location.search);
        for (let [key, value] of queryParams.entries()) {
            switch (key) {
                case "brand":
                    brand.push(value);
                    break;
                case "category":
                    category.push(value);
                    break;
                case "filter":
                    filter.push(value);
                    break;
                case "min_price":
                    min_price.push(value);
                    break;
                case "max_price":
                    max_price.push(value);
                    break;
            }
        }
        ;

        // Изменение состояния фильтров, в зависмости от их применения

        function setStateChecked(arr, elem) {
            const filterAttrValue = elem.getAttribute('data-filter');
            if (arr.includes(filterAttrValue)) elem.checked = true;
        };

        filterElems.forEach((el) => {
            switch (el.name) {
                case "brand":
                    setStateChecked(brand, el);
                    break;
                case "category":
                    setStateChecked(category, el);
                    break;
                case "filter":
                    setStateChecked(filter, el);
                    break;
                case "cost":
                    const min_priceValue = el.getAttribute('data-min-price');
                    const max_priceValue = el.getAttribute('data-max-price');
                    if (min_price.includes(min_priceValue) && max_price.includes(max_priceValue)) {
                        el.checked = true;
                    }
                    break;
            }
        });

        inputMinPrice.forEach((input) => {
            input.value = min_price
        });
        inputMaxPrice.forEach((input) => {
            input.value = max_price
        });

        // Изменения параметров фильтров
        // при выборе всех фильтров в категории

        function filtersState(arr, name) {
            if (arr.length == 0) {
                filterElems.forEach((el) => {
                    if (el.name == name) {
                        el.checked = false;
                    }
                })
            }
        };

        function allFiltersState(arr, name) {
            if (arr.length !== 0) {
                filterAllElems.forEach((el) => {
                    if (el.name == name) {
                        el.checked = false;
                    }
                })
            } else {
                filterAllElems.forEach((el) => {
                    if (el.name == name) {
                        el.checked = true;
                    }
                })
            }
        };

        allFiltersState(brand, 'brand');
        allFiltersState(category, 'category');
        allFiltersState(filter, 'filter');

        inputMinPrice.forEach((el) => {
            el.addEventListener('input', (evt) => {
                filterAllElems.forEach((cost) => {
                    if (cost.name == 'cost') {
                        cost.checked = 'checked';
                    }
                })
                if (parseInt(el.value) < parseInt(el.getAttribute('min'))) {
                    el.value = parseInt(el.getAttribute('min'));
                }
                if (parseInt(el.value) > parseInt(el.getAttribute('max'))) {
                    el.value = parseInt(el.getAttribute('max'));
                }
                min_price = Array(el.value)
                debouncedAddQuerySearch(
                    ['brand', 'category', 'filter', 'min_price', 'max_price'],
                    [brand.slice(), category.slice(), filter.slice(), min_price.slice(), max_price.slice()]
                );
            })
        })

        inputMaxPrice.forEach((el) => {
            el.addEventListener('input', (evt) => {
                filterAllElems.forEach((cost) => {
                    if (cost.name == 'cost') {
                        cost.checked = 'checked';
                    }
                })
                if (parseInt(el.value) < parseInt(el.getAttribute('min'))) {
                    el.value = parseInt(el.getAttribute('min'));
                }
                if (parseInt(el.value) > parseInt(el.getAttribute('max'))) {
                    el.value = parseInt(el.getAttribute('max'));
                }
                max_price = Array(el.value)
                debouncedAddQuerySearch(
                    ['brand', 'category', 'filter', 'min_price', 'max_price'],
                    [brand.slice(), category.slice(), filter.slice(), min_price.slice(), max_price.slice()]
                );
            })
        })

        // Фильтрация при нажатии на checkbox

        filterElems.forEach((el) => {
            const filterAttrValue = el.getAttribute('data-filter');
            el.addEventListener('click', (evt) => {
                switch (el.name) {
                    case "brand":
                        addOrRemoveQuery(brand, filterAttrValue);
                        allFiltersState(brand, 'brand');
                        break;
                    case "category":
                        addOrRemoveQuery(category, filterAttrValue);
                        allFiltersState(category, 'category');
                        break;
                    case "filter":
                        addOrRemoveQuery(filter, filterAttrValue);
                        allFiltersState(filter, 'filter');
                        break;
                    case "cost":
                        min_price = Array(el.getAttribute('data-min-price'));
                        max_price = Array(el.getAttribute('data-max-price'));
                        inputMinPrice.forEach((input) => {
                            input.value = min_price;
                        })
                        inputMaxPrice.forEach((input) => {
                            input.value = max_price;
                        })
                        break;
                }
                if (!el.classList.contains('mobile')) {
                    debouncedAddQuerySearch(
                        ['brand', 'category', 'filter', 'min_price', 'max_price'],
                        [brand.slice(), category.slice(), filter.slice(), min_price.slice(), max_price.slice()]
                    );
                }
            })
        });

        // Изменение состояния всех фильтров
        // при выборе отсутствия фильтрации

        filterAllElems.forEach((el) => {
            el.addEventListener('click', (evt) => {
                if (el.checked) {
                    if (el.name == 'brand') {
                        brand = [];
                        filtersState(brand, 'brand')
                    } else if (el.name == 'filter') {
                        filter = [];
                        filtersState(filter, 'filter')

                    } else if (el.name == 'category') {
                        category = [];
                        filtersState(category, 'category')
                    } else if (el.name == 'cost') {
                        min_price = [];
                        max_price = [];
                        inputMinPrice.forEach((input) => {
                            input.value = min_price;
                        })
                        inputMaxPrice.forEach((input) => {
                            input.value = max_price;
                        })
                    }
                }
                if (!el.classList.contains('mobile')) {
                    debouncedAddQuerySearch(
                        ['brand', 'category', 'filter', 'min_price', 'max_price'],
                        [brand.slice(), category.slice(), filter.slice(), min_price.slice(), max_price.slice()]
                    );
                }
                el.checked = true;
            });
        });

        // Применение и отмена выбранных фильтров на мобильных устройствах

        filterDenyMobile.addEventListener('click', () => {
            window.location.href = url.toString();
        });
        filterConfirmMobile.addEventListener('click', () => {
            addQuery(['brand', 'category', 'filter'], [brand.slice(), category.slice(), filter.slice()]);
        });

    }
}