import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';
import init from "./init.js";

let mesh, helper, walls, plinth;
let isHovered = false;
let isMobile = false;

if (/Mobi|Android/i.test(navigator.userAgent)) {
    isMobile = true;
}

export function paintingHome() {
    return new Promise((resolve, reject) => {
        const { sizes, camera, scene, container, controls, renderer } = init();

        const floor = new THREE.Mesh(
            new THREE.PlaneGeometry(10, 10),
            new THREE.MeshStandardMaterial({
                color: 0xffffff,
                metalness: 0,
                roughness: 0.75
            })
        );

        scene.add(new THREE.AxesHelper(1));

        const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
        directionalLight.position.set(-5, 10, 2);
        directionalLight.castShadow = true;

        directionalLight.shadow.mapSize.width = 512;
        directionalLight.shadow.mapSize.height = 512;
        directionalLight.shadow.camera.near = 0.5;
        directionalLight.shadow.camera.far = 50;

        scene.add(directionalLight);

        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        scene.add(ambientLight);

        helper = new THREE.Object3D();

        const textureLoader = new THREE.TextureLoader();

        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/path/to/draco/'); // Установите путь к декодеру DRACO

        const loader = new GLTFLoader();
        loader.setDRACOLoader(dracoLoader); // Установка DRACOLoader для сжатия моделей

        loader.load(
            '/static/assets/models/cottage_new/cottage_new.gltf',
            (gltf) => {
                mesh = gltf.scene;
                mesh.scale.set(0.5, 0.5, 0.5);
                mesh.position.y = -2;

                const boundingBox = new THREE.Box3().setFromObject(mesh);
                const center = boundingBox.getCenter(new THREE.Vector3());

                camera.position.x = 7;
                camera.position.y = center.y;
                camera.position.z = center.z + 5;
                walls = mesh.getObjectByName("walls");
                plinth = mesh.getObjectByName("plinth");

                const textureWall = textureLoader.load('/static/assets/models/cottage_new/materials/stone.png');
                const materialWall = new THREE.MeshBasicMaterial({ map: textureWall });
                walls.material = materialWall;

                scene.add(mesh);

                resolve();
            },
            (xhr) => {
                const loadPercent = (xhr.loaded / xhr.total) * 100;
                console.log(`Model ${loadPercent}% loaded.`);
            },
            reject
        );

        const tick = () => {
            controls.update();
            if (!isMobile) {
                rotateModel();
            }
            renderer.render(scene, camera);
            window.requestAnimationFrame(tick);
        };
        tick();

        container.addEventListener('mouseover', () => {
            isHovered = true;
        });

        container.addEventListener('mouseout', () => {
            isHovered = false;
        });

        function rotateModel() {
            if (mesh && !isHovered) {
                mesh.rotation.y += 0.01;
            }
        }

        function reinitRender(height = document.querySelector('#houseContainer').clientHeight) {
            sizes.width = document.querySelector('#houseContainer').clientWidth;
            sizes.height = height;

            camera.aspect = sizes.width / sizes.height;
            camera.updateProjectionMatrix();

            renderer.setSize(sizes.width, sizes.height);
            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
            renderer.render(scene, camera);
        }

        window.addEventListener('resize', () => {
            reinitRender();
        });

        document.querySelector('#openHouseFullScreen').addEventListener('click', () => {
            if (!document.fullscreenElement) {
                container.requestFullscreen();
            } else {
                document.exitFullscreen();
                reinitRender(400);
            }
        });

        function resetHouseMaterial() {
            textureLoader.load("/static/assets/models/cottage_new/materials/stone.png", function (texture) {
                const material = new THREE.MeshBasicMaterial({ map: texture });
                walls.material = material;
                walls.material.color.setHex(0xffffff);
                plinth.material.color.setHex(0xffffff);
            });
        }

        document.querySelector('#resetHouseMaterialButton').addEventListener('click', () => {
            resetHouseMaterial();
        });

        const renderScene = () => {
            renderer.render(scene, camera);
        };

        const downloadImage = () => {
            const canvas = renderer.domElement;
            const dataURL = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = dataURL;
            link.download = 'scene.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        document.querySelector('#downloadButton').addEventListener('click', () => {
            renderScene();
            downloadImage();
        });

    });

}

export function changeHouseMaterial(materialName) {
    const materials = {
        "stone": "/static/assets/models/cottage_new/materials/stone.png",
        "little_stone": "/static/assets/models/cottage_new/materials/little_stone.png"
    };

    const textureLoader = new THREE.TextureLoader();
    textureLoader.load(materials[materialName], function (texture) {
        const material = new THREE.MeshBasicMaterial({ map: texture });
        const currentColor = walls.material.color.getHex();
        walls.material = material;
        changeHouseWallColor(currentColor);
    });
}

export function changeHouseWallColor(colorHex) {
    walls.material.color.set(colorHex);
}

export function changeHousePlinthColor(textureURL) {
    const textureLoaderPlinth = new THREE.TextureLoader();
    textureLoaderPlinth.load(textureURL, function (texture) {

        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;

        const repeatX = 60;
        const repeatY = 60;
        texture.repeat.set(repeatX, repeatY);

        const material = new THREE.MeshBasicMaterial({ map: texture });

        plinth.material = material;

    });
}
