import { docOverflowHidden, docOverflowRemove, docOverflowRemoveTimeout } from "./utils.js";

const searcherInput = document.getElementById('input__search__global');
const searcherInputMobile = document.getElementById('input__search__global__mobile');

const searcherMenu = document.getElementById('global__searcher');
const searcherMenuMobile = document.getElementById('global__searcher__mobile');

const clearInputBtn = document.querySelector('.clear-input');
const closeBtn = document.querySelector('.close_searcher');
const searcherMenuMobileContainer = document.getElementById('global__searcher__mobile__container');

export function globalSearcher() {
    
    if(searcherInput) {
        setResult(searcherInput, searcherMenu);
    }
    if(searcherInput) {
        searcherInput.addEventListener('blur',() => {
            const clickHandler = (e) => {
                if (!searcherMenu.contains(e.target) && e.target !== searcherInput) {
                    closeMenu();
                    document.removeEventListener('click', clickHandler);
                    docOverflowRemove()
                }
            };
            document.addEventListener('click', clickHandler);
        });
    }
    if (searcherInputMobile) {
        setResult(searcherInputMobile, searcherMenuMobile);
    }
    if(closeBtn) {
        closeBtn.addEventListener('click',() => {
            closeMenu()
            searcherInputMobile.value = ''
            searcherMenuMobileContainer.classList.add('no_results');
            clearInputBtn.classList.remove('active');
        });
    }
    if (clearInputBtn) {
        clearInputBtn.addEventListener('click',() => {
            searcherInputMobile.value = ''
            searcherMenuMobileContainer.classList.add('no_results');
            clearInputBtn.classList.remove('active');
        });
    }
}

const stickyHeader = document.getElementById('sticky-header');
const main = document.querySelector('main');

const openMenu = document.querySelectorAll('.open-some-menu');
const inset = document.querySelector('.__inset');

const headerContainer = document.querySelector('.header-section__container');
function closeMenu() {
    if (searcherMenuMobileContainer) {
        searcherMenuMobileContainer.classList.remove('active');
        searcherMenuMobileContainer.style.opacity = '';
        searcherMenuMobileContainer.style.maxHeight = 0;
        inset.classList.remove('show');
        openMenu.forEach((el) => { 
            el.classList.remove('active');
        })
    }
    if (searcherMenu) {
        searcherMenu.classList.remove('no_results');
        searcherMenu.classList.remove('active');
    }
    docOverflowRemoveTimeout()
}

function closeAllMenusDesk() {
    const allMenus = document.querySelectorAll('.some-menu');
    openMenu.forEach((btn) => {
        btn.classList.remove('active');
    });
    inset.classList.remove('show');
    allMenus.forEach((e) => {
        e.style.maxHeight = '0';
        e.style.opacity = '';
    });
}
function nothingFound(menuToAppend) {
    const arrMenu = Array.from(menuToAppend.children)
    const noResultsFound = arrMenu.find(el => el.classList.contains('global-searcher__no-results'));
    if(!noResultsFound) {
        arrMenu.forEach((el) => {
            if(!el.classList.contains('global-searcher_mobile__input-block')) el.style.display = 'none';
        });
        const noResults = document.createElement('div');
        noResults.classList.add('global-searcher__no-results');
        noResults.innerHTML = `
            Ничего не найдено
        `;
        menuToAppend.appendChild(noResults)
    }
}

function somethingFound(menuToAppend) {
    const arrMenu = Array.from(menuToAppend.children)
    const noResultsFound = arrMenu.find(el => el.classList.contains('global-searcher__no-results'));
    if(noResultsFound) {
        arrMenu.forEach((el) => {
            el.style.display = 'flex';
        });
        menuToAppend.removeChild(noResultsFound);
    }
}

async function getSearchData(value) {
    const response = await fetch('/api/search?q=' + value, {
        method: "GET",
    });
    if(response.ok) return response.json();
    else throw new Error(response.statusText);
}



function setResult(input, menu) {
    const searcherBlock = menu.querySelector('.global-searcher__results-block');
    input.addEventListener('input', () => {
        const fragment = document.createDocumentFragment();
        const productsFragment = document.createDocumentFragment();
        const subcategoryFragment = document.createDocumentFragment();
        if (input.value !== '') {
            menu.classList.add('active');
            if(window.innerWidth > 769) {
                closeAllMenusDesk()
            }

            setTimeout(() => {
                main.style.paddingTop = headerContainer.clientHeight + 'px';
            }, 400);
            
            docOverflowHidden()
            getSearchData(input.value)
            .then(data => {
                if(data['categories'].length == 0 & data['products'].length == 0) {
                    nothingFound(menu)
                } else {
                    somethingFound(menu)
                    Array.from(data['categories']).forEach(category => {
                        const categoryName = category.name;
                        const searchBullet = document.createElement('a');
                        const hr = document.createElement('hr');
                        searchBullet.href = '/catalog/' + category.slug;
                        searchBullet.classList.add('search-list-bullet');
                        searchBullet.innerHTML = `
                        <div class="search-list-bullet__block">
                            <div class="button button_icon_small">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21 21L17.5001 17.5M20 11.5C20 16.1944 16.1944 20 11.5 20C6.80558 20 3 16.1944 3 11.5C3 6.80558 6.80558 3 11.5 3C16.1944 3 20 6.80558 20 11.5Z"
                                        stroke="#1C1917" stroke-opacity="0.4" stroke-width="2"
                                        stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div class="button_text_href">
                                ${categoryName}
                            </div>
                        </div>
                        <div class="button button_icon_small">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 6L15 12L9 18" stroke="#1C1917" stroke-opacity="0.4"
                                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        `;
                        fragment.appendChild(searchBullet);
                        fragment.appendChild(hr);
                    });
                    const productsBlock = menu.querySelector('.global-searcher__products-block');
                    const productsSlider = menu.querySelector('.products_from_searcher');
                    const subcategoriesBlock = menu.querySelector('.global-searcher__categories-block');
                    Array.from(data['subcategories']).forEach(subcategory => {
                        const categoryBtn = document.createElement('a');
                        categoryBtn.href = '/catalog/' + subcategory.slug;
                        categoryBtn.classList.add('button', 'button_select_large')
                        categoryBtn.innerHTML = `
                            ${subcategory.name}
                        `;
                        subcategoryFragment.appendChild(categoryBtn);
                    });
                    
                    if(data['products'].length > 0) {
                        Array.from(data['products']).forEach(product => {
                            productsBlock.style.display = 'flex'
                            let productBasicPrice = ''
                            if(!product.price == product.basic_price) {
                                productBasicPrice = product.basic_price + ' ₽'
                            }
                            const productCard = document.createElement('a');
                            productCard.href = '/product/' + product.slug;
                            productCard.classList.add('swiper-slide', 'product-card', 'product-card_size_small');
                            productCard.style.marginRight = getRem() + 'px';
                            productCard.innerHTML = `
                                <div class="product-card_size_small__img">
                                    <img loading="lazy" src="${product.main_photo}">
                                </div>
                                <div class="product-card_size_small__title-block">
                                    <div class="product-card_size_small__cost-block">
                                        <div class="product-card_size_small__cost-block__cost">
                                            ${product.price} ₽
                                        </div>
                                        <div class="product-card_size_small__cost-block__cost_prev">
                                            ${productBasicPrice}
                                        </div>
                                    </div>
                                    <div class="product-card_size_small__title">
                                        ${product.name}
                                    </div>
                                </div>
                            `;
                            productsFragment.appendChild(productCard);
                        });
                        searcherBlock.innerHTML = '';
                        subcategoriesBlock.innerHTML = '';
                        productsSlider.innerHTML = '';
                        searcherBlock.appendChild(fragment);
                        subcategoriesBlock.appendChild(subcategoryFragment);
                        productsSlider.appendChild(productsFragment);   
                    } else {
                        productsBlock.style.display = 'none'
                    }
                }
            })
        } else {
            nothingFound(menu)
        }
    });
}

function getRem() {
    var element = document.querySelector("html");
    var fontSize = window.getComputedStyle(element).fontSize;
    var numericFontSize = parseInt(fontSize, 10);
    return fontSize.slice(0, -2)
}