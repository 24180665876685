export function attachFile() {
    const fileInput = document.getElementById('file');

    let inputEvent = new Event('input', {
        bubbles: true,
        cancelable: true,
    });

    if(fileInput) {
        const requisitesName = document.querySelector('.requisites_name');
        const clearBtn = document.querySelector('.btn_clear');
        fileInput.addEventListener('change',() => {
            const inputMail = document.querySelector('input[name="email"]');
            inputMail.dispatchEvent(inputEvent);

            fileInput.value ? fileInput.parentElement.classList.add('active') : fileInput.parentElement.classList.remove('active');
            requisitesName.innerText = fileInput.files[0].name;
        });
        clearBtn.addEventListener('click',() => {
            requisitesName.innerText = '';
            fileInput.value = '';
            fileInput.parentElement.classList.remove('active');
        });
    }
}