export function palette() {
    const buttons = Array.from(document.querySelectorAll('.openPalletModal'))
    if (buttons.length > 0) {
        buttons.forEach(button => {
            button.addEventListener('click', (event) => {
                const palletId = event.target.dataset.palletid;
                const titleCard = button.parentNode.querySelector('.pallet-section__block__name');
                const titleModal = document.querySelector('.modal-content__color-title');
                titleModal.innerText = titleCard.innerText;
                getShades(palletId)
                    .then(shades => {
                        setShades(shades)
                    })
            });
        });
    }

}

function setShades(shades) {
    const container = document.querySelector('.modal-content__colors'); // Замените #container на ваш селектор
    container.innerHTML = ''
    shades.forEach(shade => {
        const div = document.createElement('div');
        div.classList.add('modal-content__color');
        div.style.backgroundColor = shade.code_hex;

        const innerDiv = document.createElement('div');
        innerDiv.classList.add('modal-content__color__name');
        innerDiv.textContent = shade.name;

        div.appendChild(innerDiv);
        container.appendChild(div);
    })
}

async function getShades(palletId) {
    const response = await fetch('/api/pallets/' + palletId + '/get-shades/', {
        method: "GET",
    });
    if (response.ok) return response.json();
    else throw new Error(response.statusText);
};