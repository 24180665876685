import { noPaginationBlock } from "../../pagination.js";

export function AddFaqCards(data,list) {
    const fragment = document.createDocumentFragment();
    data.results.forEach((el) => {
        const newFaq = addCard(el);

        addListeners(newFaq);
       
        fragment.appendChild(newFaq);

        const hr = document.createElement('hr');
        fragment.appendChild(hr);  
    })
    list.appendChild(fragment);
    if(!data.next) {
        noPaginationBlock();
    }
}


function addCard(el) {
    const newFaq = document.createElement('div');
    newFaq.classList.add('about-us-faq__container__accordion-item', 'accordion-item');
    newFaq.innerHTML = `
        <div class="about-us-faq__container__accordion-item accordion-header">
            <div class="about-us-faq__container__title">${el['question']}</div>
            <div class="about-us-faq__container  button button_icon_small">
                <div class="line"></div>
                <div class="line vertical"></div>
            </div>
        </div>
        <div class="about-us-faq__container__accordion-item accordion-body">
            <div class="about-us-faq__container accordion-content">
                ${el['answer']}
            </div>
        </div>
    `;
    return newFaq;
}

function addListeners(el) {
    el.addEventListener('click',(e) => {
        const arrayElems = Array.from(e.target.children);
        const findBodyElem = arrayElems.find((el) => el.className.includes('accordion-body'));
        const findHeaderElem = arrayElems.find((el) => el.className.includes('accordion-header'));
        if (findHeaderElem) {
            findHeaderElem?.classList.toggle('show')
        };
        if (findBodyElem) {
            if (findBodyElem.classList.contains('show')) {
                findBodyElem.style.maxHeight = null;
                findBodyElem.classList.remove('show');
            } else {
                findBodyElem.style.maxHeight = findBodyElem.scrollHeight * 2 + "px";
                findBodyElem.classList.add('show');
            }
        };
        const aboutUsAccordion = document.querySelectorAll('.about-us-faq__container__accordion-item');
        aboutUsAccordion.forEach((el) => {
            const targetBody = e.target.querySelector('.accordion-body');
            const targetHeader = e.target.querySelector('.accordion-header');
            if(el !== e.target && el !== targetHeader && el !== targetBody) {
                el.classList.remove('show');
                el.style.maxHeight = null;
            }
        })
    })
}