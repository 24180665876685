import {changeHouseMaterial} from "./paintingHome/script.js";

export function colorMaterial() {
    const colors= document.querySelectorAll('.color-tone');
    const materials = document.querySelectorAll('.material')

    colors.forEach((el) => {
        el.addEventListener('click', (evt) => {
            const target = evt.target;
            if (target.classList.contains('choosen')) {
                target.classList.remove('choosen');
            } else {
                colors.forEach((color) => {
                    color.classList.remove('choosen')
                });
                target.classList.add('choosen');
            }
        })
    });


    materials.forEach((el) => {
        el.addEventListener('click', (e) => {
            const target = e.target;
            changeHouseMaterial(target.attributes['data-material-name'].value)
            if (target.classList.contains('choosen')) {
                target.classList.remove('choosen');
            } else {
                materials.forEach((material) => {
                    material.classList.remove('choosen')
                });
                target.classList.add('choosen');
            }
        })
    })
}