import { userLoggedIn } from "./cookies.js";
import { docOverflowRemove } from "./utils.js";
import { sendForm } from "./validation.js";

export function modals() {
  const allModals = document.querySelectorAll(".modal");

  $(".open-modal").click((e) => {
    // В data-type и data-type-modal Название вашего модального окна
    closeModal();
    const modalValue = e.target.attributes["data-type-modal"].value;
    const modalElement = document.querySelector(`[data-type="${modalValue}"]`);
    modalElement.attributes["data-modal-state"].value = "open";
    document.documentElement.style.overflow = "hidden";
  });

  $(".btn-modal-close").click(closeModal);
  $(".modal-inset").click(closeModal);

  function closeModal() {
    allModals.forEach((el) => {
      el.attributes["data-modal-state"].value = "close";
    });
    document.documentElement.style.overflow = null;
  }

  // Модальное окно акции
  const salesModalImg = document.getElementById("modal__stocks__img");
  const salesModalTitle = document.getElementById("modal__stocks__title");
  const salesModalText = document.getElementById("modal__stocks__text");
  const salesModalFile = document.getElementById("file-download");
  const salesModalFileFormat = document.getElementById("file-download__format");
  const salesModalFileSize = document.getElementById("file-download__size");
  const salesModalFileTitle = document.getElementById("file-download__title");

  const callBackBtn = document.querySelector(".openCallBack");

  const getSalesModal = document.querySelectorAll(".open-modal");
  getSalesModal.forEach((el) => {
    const getType = el.getAttribute("data-type-modal");
    if (getType == "sales-modal") {
      el.addEventListener("click", () => {
        const elID = el.getAttribute("data-id");
        const selectInputs = document.querySelectorAll(".__select__input");
        const selectLabels = document.querySelectorAll(".__select__label");
        const curProm = document.getElementById("current_promotion");
        getStock(elID).then((data) => {
          selectInputs.forEach((input) => {
            if (input.value == elID) curProm.setAttribute("data-id", elID);
          });
          selectLabels.forEach((label) => {
            if (label.id == elID) label.click();
          });
          callBackBtn.setAttribute("data-id", elID);
          data.file
            ? (salesModalFile.style.display = "flex")
            : (salesModalFile.style.display = "none");
          let img = data.photo_desktop
            ? data.photo_desktop
            : "/static/assets/images/img/stub_vertical.png";
          salesModalTitle.innerText = data.title;
          salesModalImg.src = img;
          salesModalText.innerHTML = data.description;
          salesModalFile.href = data.file;
          salesModalFileFormat.innerText = "Скачать ." + data.file_format;
          salesModalFileSize.innerText = data.file_size + "MB";
          salesModalFileTitle.innerText = data.file_name;
          window.innerWidth < 768
            ? (el.style.overflow = "scroll")
            : (el.style.overflow = "hidden");
        });
      });
    }
  });

  const callBackForm = document.querySelector(".modal-content_callback__form");
  if (callBackForm) {
    const selectors = document
      .querySelector(".modal-content_callback__form")
      .querySelectorAll(".__select__input");
    const curProm = document.getElementById("current_promotion");
    if (selectors.length > 0) {
      selectors.forEach((select) => {
        select.addEventListener("click", () => {
          curProm.setAttribute("data-id", select.value);
        });
      });
    }
  }

  const stocksHref = document.querySelectorAll("a.promo-card");
  stocksHref.forEach((stock) => {
    const stockId = stock.getAttribute("data-id");
    const url = new URL(stock.href);
    url.searchParams.set("id", stockId);
    stock.href = url.toString();
  });

  const currentLink = window.location.href;
  if (currentLink.includes("/stocks")) {
    document.addEventListener("DOMContentLoaded", () => {
      const queryId = window.location.search.replace(/\D/g, "");
      const cards = document.querySelectorAll(".promo-card");
      cards.forEach((card) => {
        const cardId = card.getAttribute("data-id");
        if (queryId == cardId) {
          setTimeout(() => {
            if (card.nodeName !== "A") card.click();
          }, 150);
        }
      });
    });
  }

  const dismissDiscountBtn = document.querySelector(".btn-dismiss-discount");
  if (dismissDiscountBtn) {
    dismissDiscountBtn.addEventListener("click", () => {
      sessionStorage.setItem("dismissDiscount", true);
    });
  }
  const discountModal = document.querySelector(
    '.modal[data-type="personal-discount"]',
  );
  if (discountModal) {
    if (!userLoggedIn()) {
      if (!sessionStorage.dismissDiscount) {
        discountModal.setAttribute("data-modal-state", "open");
      }
    }
  }
}

async function getStock(curId) {
  const response = await fetch("/api/promotions/" + curId, {
    method: "GET",
  });
  if (response.ok) return response.json();
  else throw new Error(response.statusText);
}
