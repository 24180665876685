import { changeBasketWithValue } from './basket.js';
import {
    getWishlistFromCookie, 
    removeAllFromWishlist, 
    wishlistChangeFetch, 
    removeFromWishlist, 
    addToWishlist,
    getBasketFromCookie,
    addToBasket,
    removeFromBasket,
    basketChangeFetch,
    userLoggedIn,
    getCookiesWishlist,
} from './cookies.js'
import { debounce, findParentWithClass } from './utils.js';
import { sendForm } from './validation.js';

export function wishlist() {
    const wishlistValue = document.querySelector('.wishlist-value');
    const wishlistBtn = document.querySelectorAll('.add_to_wishlist');
    const removeAllBtn = document.querySelector('.remove_all_from_wishlist');

    const modalToDeleteBtn = document.querySelector('button[data-type-modal="clear-favorites"]')

    const wishlistTurnkeyBtns = document.querySelectorAll('.add_to_wishlist_turkney-card_interactive')

    const wishlistContainer = document.querySelector('.favourite-section__container');

    const getCookies = getCookiesWishlist();
    document.addEventListener('DOMContentLoaded',() => {

        if(wishlistValue) {
            if(userLoggedIn()) {
                if(wishlistValue.innerText.trim() !== '0') {
                    wishlistValue.style.display = 'flex';
                };
            } else {
                if(getWishlistFromCookie().length > 0) {
                    let totalQuantity = '';
                    getWishlistFromCookie().forEach((el) => {
                        const quantity = 1;
                        totalQuantity = Number(totalQuantity) + quantity;
                    })
                    wishlistValue.innerText = totalQuantity;
                    wishlistValue.style.display = 'flex';
                }
            }
        }

        if(wishlistBtn.length > 0) {
            wishlistBtn.forEach((btn) => {
                const findIdVal = btn.querySelector('input').value;
                if(!userLoggedIn()) {
                    let wishlist = getWishlistFromCookie();
                    if(wishlist.includes(findIdVal)) btn.classList.add('favourited');
                }
                btn.addEventListener('click', () => {
                    if (btn.classList.contains('favourited')) {
                        btn.classList.remove('favourited');
                        changeBubbleValue('wishlist')
                        if(userLoggedIn()) {
                            wishlistChangeFetch(findIdVal, 'remove/', userLoggedIn());
                        } else {
                            removeFromWishlist(findIdVal);
                        }
                        
                    } else {
                        btn.classList.add('favourited');
                        changeBubbleValue('wishlist','plus')
                        if(userLoggedIn()) {
                            wishlistChangeFetch(findIdVal, 'add/', userLoggedIn());
                        } else {
                            addToWishlist(findIdVal);
                        }
                    };
                });
            });
        };

        if(wishlistTurnkeyBtns.length > 0) {
            wishlistTurnkeyBtns.forEach((btn) => {

                const card = findParentWithClass(btn, 'turkney-card');
                const productCards = card.querySelectorAll('.product-card_size_small');
                let flag = true;
                let wishlist = getWishlistFromCookie();
                productCards.forEach((card) => {
                    const dataId = card.getAttribute('data-id');
                    if(!wishlist.includes(dataId)) flag = false;
                });
                if(flag) btn.classList.add('favourited')
                btn.addEventListener('click',() => {
                    let productCardsId = [];
                    productCards.forEach((card) => {
                        const dataId = card.getAttribute('data-id');
                        productCardsId.push(dataId)
                    });
                    if(btn.classList.contains('favourited')) {
                        btn.classList.remove('favourited');
                        productCardsId.forEach((id) => {
                            changeBubbleValue('wishlist');
                            if(userLoggedIn()) {
                                wishlistChangeFetch(id, 'remove/', userLoggedIn());
                            } else {
                                removeFromWishlist(id);
                            }
                        })
                    } else {
                        btn.classList.add('favourited');
                        productCardsId.forEach((id) => {
                            changeBubbleValue('wishlist','plus');
                            if(userLoggedIn()) {
                                wishlistChangeFetch(id, 'add/', userLoggedIn());
                            } else {
                                if(!wishlist.includes(id)) addToWishlist(id);
                            }
                        })
                    };
                });
            });
        };
    });
    if(window.location.pathname.includes('/wishlist/')) {
        if(userLoggedIn()) {
            const cards = wishlistContainer.querySelectorAll('.product_card');
            if(cards.length == 0) {
                modalToDeleteBtn.parentNode.removeChild(modalToDeleteBtn)
            }
            if(removeAllBtn) {
                removeAllBtn.addEventListener('click', () => {
                    wishlistBtn.forEach((btn) => {
                        const findIdVal = btn.querySelector('input').value;
                        btn.classList.remove('favourited');
                        wishlistChangeFetch(findIdVal, 'remove/', userLoggedIn());
                    });
                    window.location.reload();
                });
            };
        } else {
            if(getCookies.length > 3) {
                document.addEventListener('DOMContentLoaded', () => {
                    const splitedCookies = getCookies.split('","')
                    const replacedCookies = splitedCookies.map(str => str.replace(/\["|"\]/g, ''));
                    
                    const basket = getBasketFromCookie();

                    replacedCookies.forEach((el) => {
                        getProduct(el)
                        .then(data => {
                            const popularBadge = data.popular ? `<div class="badge badge_category_small badge_terteriary">Популярное</div>` : '';
                            const profitablyBadge = data.profitably ? `<div class="badge badge_category_small badge_accent">Выгодно!</div>` : '';
                            let finishPrice = data.price ? `${data.price} ₽` > 0 : "ПОД ЗАКАЗ"
                            const noProductsSect = document.querySelector('.no-products');
                            if(noProductsSect) wishlistContainer.removeChild(noProductsSect);
                            const productCard = document.createElement('div');
                            let productInBasket = ''
                            let productBasketQuantity = '';
                            basket.forEach((el) => {
                                if(el['product'] == data.id) {
                                    productBasketQuantity = el['quantity'];
                                    productInBasket = 'in-basket'
                                }
                            });
                            productCard.classList.add('product-card', 'product-card_size_basic', 'product-accordion');
                            productCard.innerHTML = `
                                <div class="product-card_size_basic__info-block">
                                    <div class="product-card_size_basic__img-block">
                                        <a href="/product/${data.slug}" class="product-card_size_basic__img-block__img">
                                            <img loading="lazy" src="${data.main_photo}">
                                        </a>
                                        <div class="product-card_size_basic__badge-block">
                                            ${popularBadge}
                                            ${profitablyBadge}
                                        </div>
                                        <button class="button button_icon_large add_to_wishlist_new favourited">
                                            <input type="hidden" value="${data.id}">
                                            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4909 6.84775C13.8251 3.7312 9.37965 2.89286 6.03958 5.74668C2.69952 8.60051 2.22928 13.372 4.85225 16.7472C7.03308 19.5535 13.633 25.4721 15.7961 27.3878C16.0381 27.6021 16.1591 27.7093 16.3003 27.7514C16.4235 27.7881 16.5583 27.7881 16.6814 27.7514C16.8226 27.7093 16.9436 27.6021 17.1856 27.3878C19.3487 25.4721 25.9486 19.5535 28.1295 16.7472C30.7524 13.372 30.3396 8.57049 26.9421 5.74668C23.5447 2.92288 19.1567 3.7312 16.4909 6.84775Z" stroke="#EE7F01" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>                        
                                        </button>
                                    </div>
                                    <a href="/product/${data.slug}" class="product-card_size_basic__title-block">
                                        <div class="product-card_size_basic__cost-block">
                                            <div class="product-card_size_basic__cost-block__cost">
                                                ${finishPrice}
                                            </div>
                                            <div class="product-card_size_basic__cost-block__cost_prev">
                                                ${data.basic_price} ₽
                                            </div>
                                        </div>
                                        <div class="product-card_size_basic__title-block__title">
                                            ${data.name}
                                        </div>
                                    </a>
                                </div>
                                <div class="product-accordion__button">
                                    <button class="button button_accent button_size_small ${productInBasket} add_to_basket">
                                        В корзину
                                        <input type="hidden" class="product__id" value="${data.id}">
                                    </button>
                                    <button class="button button_counter_basic">
                                        <svg class="change_counter_value minus" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 12H19" stroke="#1C1917" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <input type="text" value="${productBasketQuantity}">
                                        <svg class="change_counter_value plus" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 5V19M5 12H19" stroke="#1C1917" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                </div>
                            `;
                            wishlistContainer.appendChild(productCard);

                            // Работа с корзиной из вишлиста

                            const addToBasketBtn = productCard.querySelector('.product-accordion__button');
                            productCard.addEventListener('mouseover',() => {
                                addToBasketBtn.classList.add('active')
                            });
                            productCard.addEventListener('mouseout',() => {
                                addToBasketBtn.classList.remove('active')
                            });

                            const productId = data.id;
                            const changeBtnsCard = productCard.querySelectorAll('.change_counter_value');
                            const addBtn = productCard.querySelector('.add_to_basket')
                            addBtn.addEventListener('click',() => {
                                const parent = addBtn.parentNode;
                                const id = data.id;
                                const parsedId = parseInt(id)
                                const input = Array.from(parent.querySelector('.button_counter_basic').children).find(el => el.localName == 'input')
                                input.value = 1;
                                addBtn.classList.add('in-basket');
                                if(userLoggedIn()) {
                                    let producObj = {};
                                    producObj['product'] = parsedId;
                                    producObj['quantity'] = input.value;
                                    basketChangeFetch(producObj, 'add/', userLoggedIn());
                                } else {
                                    removeFromBasket(id);
                                    addToBasket(id, 1);
                                }
                            });
                            const input = productCard.querySelector('.button_counter_basic').querySelector('input')
                            input.addEventListener('input',()=>{
                                input.value = input.value.replace(/\D/g, '');
                                debouncedChangeWithValue(addBtn);
                            });
                            changeBtnsCard.forEach((btn) => {
                                const findInput = Array.from(btn.parentNode.children).find(el => el.localName == 'input');
                                const findAddBtn = Array.from(btn.parentNode.parentNode.children).find(el => el.classList.contains('add_to_basket'));
                                btn.addEventListener('click',() => {
                                    let inputVal = parseInt(findInput.value);
                                    if (btn.classList.contains('plus')) {
                                        inputVal += 1;
                                    }
                                    else if (btn.classList.contains('minus')) {
                                        inputVal -= 1;
                                    }
                                    removeFromBasket(productId);
                                    findInput.value = inputVal >= 0 ? inputVal : 0;
                                    if (inputVal <= 0) {
                                        findAddBtn.classList.remove('in-basket');
                                     }else {
                                        addToBasket(productId, findInput.value);
                                    }
                                });
                            });



                            const wishlistNewBtn = productCard.querySelector('.add_to_wishlist_new');
                            const findIdVal = wishlistNewBtn.querySelector('input').value;
                            wishlistNewBtn.addEventListener('click',() => {
                                if (wishlistNewBtn.classList.contains('favourited')) {
                                    wishlistNewBtn.classList.remove('favourited');
                                    changeBubbleValue('wishlist');
                                    removeFromWishlist(findIdVal);
                                } else {
                                    wishlistNewBtn.classList.add('favourited');
                                    changeBubbleValue('wishlist','plus');
                                    addToWishlist(findIdVal);
                                }
                            })
                        })
                    })
                    if(removeAllBtn) {
                        removeAllBtn.addEventListener('click', () => {
                            const wishlistNewBtns = document.querySelectorAll('.add_to_wishlist_new');
                            removeAllFromWishlist();
                            wishlistBtn.forEach((btn) => {
                                btn.classList.remove('favourited')
                            });
                            wishlistNewBtns.forEach((btn) => {
                                btn.classList.remove('favourited')
                            });
                            window.location.reload();
                        });
                    }
                })
            } else {
                document.addEventListener('DOMContentLoaded',() => {
                    modalToDeleteBtn.parentElement.removeChild(modalToDeleteBtn);
                    wishlistContainer.innerHTML = `
                        <div class="no-products">
                            <span>В избранном пусто</span>
                            <a href="/catalog/peny-germetiki/" class="button_text_basic button_text_basic_accent">
                                В каталог
                            </a>
                            <a href="/catalog/peny-germetiki/" class="button button_size_basic button_accent">
                                В каталог
                            </a>
                        </div>
                    `
                })
            } 
        }
    }
};

export function changeBubbleValue(bubble, sign) {
    if(bubble == 'basket') {
        const bubbleValue = document.querySelector(`.${bubble}-value`);
        const products = getBasketFromCookie();
        const productsObj = {};
        const updatedProducts = [];
        products.forEach(product=>{
            if(product.quantity === null) {
                product.quantity = 0;
            };
            updatedProducts.push(product);
        });
        productsObj.products = updatedProducts;
        if(!userLoggedIn()) {
            sendForm(productsObj, '/api/basket/get-basket-info/')
            .then(response=>{
                if(response.status) {
                    const basketInfo = response.basket_info;
                    const totalQuantity = basketInfo.total_quantity;
                    
                    bubbleValue.style.display = 'none';
                    if(totalQuantity !== 0) bubbleValue.style.display = 'flex';

                    bubbleValue.innerText = totalQuantity;
                } else {
                    return;
                }
            });
        };
    } else {
        const valueBubble = document.querySelector(`.${bubble}-value`);
        let value = Number(valueBubble.innerText.trim());
        let newValue = '';
        if (sign == 'plus') {
            newValue = value + 1;
        } else {
            newValue = value - 1;
        }
        valueBubble.innerText = newValue;
        valueBubble.style.display = 'none';
        if(valueBubble.innerText.trim() !== '0') valueBubble.style.display = 'flex';
    }
}

async function getProduct(curId) {
    const response = await fetch('/api/products/' + curId, {
        method: "GET",
    });
    if(response.ok) return response.json();
    else throw new Error(response.statusText);
};

const debouncedChangeWithValue = debounce(changeBasketWithValue, 500);