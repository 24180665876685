

export const userLoggedIn = () => {
    return document.cookie.replace(/(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/, "$1");
}

export const getCookiesBasket = () => {
    return decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)basket\s*\=\s*([^;]*).*$)|^.*$/, "$1"));
}
export const getCookiesWishlist = () => {
    return decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)wishlist\s*\=\s*([^;]*).*$)|^.*$/, "$1"));
}


export function cookieAcceptation() {
    document.addEventListener('DOMContentLoaded', function () {
        const container = document.querySelector(".cookie-acceptation");
    
        if (!document.cookie.includes("warning=true")) {
            if(container) {
                container.style.display = "flex";
            }
        }
    
        if (document.querySelector(".cookie-acceptation")) {
            const apply = document.querySelector(".cookie-acceptation__accept");
            const deny = document.querySelectorAll(".cookie-acceptation__decline");
            if(apply) {
                apply.addEventListener("click", () => {
                    container.style.display = "none";
                    document.cookie = "warning=true; max-age=2592000; path=/";
                });
            }
            if(deny.length > 0) {
                deny.forEach((btn) => {
                    btn.addEventListener("click", () => {
                        container.style.display = "none";
                    })
                })
            }
        }
    });
}

// Куки basket'а


export function getCookieIndividual() {
    let individualCookies = [];
    const basket = getCookiesBasket()
    if(basket) {
        const getCookiesParse = JSON.parse(basket);
        getCookiesParse.forEach(obj => {
            individualCookies.push({
                product: obj.product,
                quantity: obj.quantity
            });
        });
    }
   
    return individualCookies;
}

export function getBasketFromCookie() {
    const basketCookies = getCookiesBasket();
    return basketCookies ? JSON.parse(basketCookies) : [];
}

export function removeAllFromBasket() {
    let baskets = getBasketFromCookie();
    setCookie('basket', JSON.stringify(baskets), { secure: true, 'max-age': -1 });
}

export function changeBasketValue() {
    const basket = getBasketFromCookie();
    const basketValue = document.querySelector('.basket-value');
    if(basketValue.style.display !== 'flex') basketValue.style.display = 'flex';
    let allItemsQuantity = 0;
    basket.forEach((productObj) => {
        allItemsQuantity = allItemsQuantity + productObj.quantity;
    });
    if(allItemsQuantity == 0) basketValue.style.display = 'none';
    basketValue.innerText = allItemsQuantity;
}

export async function basketChangeFetch(elem, url, tokenVal) {
    const csrfToken = $('[name=csrfmiddlewaretoken]').val();
    const response = await fetch('/api/basket/' + url, {
        method: "POST",
        headers: {
            'Content-Type': "application/json",
            'X-CSRFToken': csrfToken,
            'Authorization': 'Token ' + tokenVal,
        },
        body: JSON.stringify(elem),
    });
    if(response.ok) return response.json();
    else throw new Error(response.statusText);
};

export function addToBasket(productId, quantity) {
    let basket = getBasketFromCookie();
    basket.push({
        "product": productId,
        "quantity": quantity,
    });
    setCookie('basket', JSON.stringify(basket), { secure: true });
    // changeBasketValue()
}

export function removeFromBasket(productId) {
    let basket = getBasketFromCookie();
    const index = basket.findIndex(item => parseInt(item.product) === parseInt(productId)) 
    if (index !== -1) {
        basket.splice(index, 1);
    }
    setCookie('basket', JSON.stringify(basket), { secure: true });
};


// Куки wishlist'а

export function addToWishlist(productId) {
    let wishlist = getWishlistFromCookie();
    if(!wishlist.includes(productId)) wishlist.push(productId);
    setCookie('wishlist', JSON.stringify(wishlist), { secure: true });
};

export function removeFromWishlist(productId) {
    let wishlists = getWishlistFromCookie();
    const index = wishlists.indexOf(productId);
    if (index !== -1) {
        wishlists.splice(index, 1);
        setCookie('wishlist', JSON.stringify(wishlists), { secure: true });
    }
};

export function getWishlistFromCookie() {
    const wishlistCookies = getCookiesWishlist();
    return wishlistCookies ? JSON.parse(wishlistCookies) : [];
};

export function removeAllFromWishlist() {
    let wishlists = getWishlistFromCookie();
    setCookie('wishlist', JSON.stringify(wishlists), { secure: true, 'max-age': -1 });
};

export function wishlistChangeFetch(elem, url, tokenVal) {
    let producObj = {}
    producObj['product'] = elem;
    const csrfToken = $('[name=csrfmiddlewaretoken]').val();
    fetch('/api/wishlist/' + url, {
        method: "POST",
        headers: {
            'Content-Type': "application/json",
            'X-CSRFToken': csrfToken,
            'Authorization': 'Token ' + tokenVal,
        },
        body: JSON.stringify(producObj),
    });
};


// Общая работа с куки

export function setCookie(name, value, options = {}) {
    options = {
        path: '/',
      ...options
    };
  
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }
  
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
  
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }
  
    document.cookie = updatedCookie;
};

