export function showMore() {
    const showMoreBtn = document.querySelectorAll('.showMoreBtn');
    if (showMoreBtn.length > 0) {
        showMoreBtn.forEach((e) => {
            let currentText = e.innerText;
            e.addEventListener('click',() => {
                const dataAttr = e.dataset.showMore;
                const findBody = document.querySelector(`[data-show-body="${dataAttr}"]`);
                if(findBody.classList.contains('show')) {
                    findBody.classList.remove('show');
                    findBody.style.maxHeight = null;               
                    e.innerText = currentText;
                } else {
                    findBody.style.maxHeight = findBody.scrollHeight * 2 + "px";   
                    findBody.classList.add('show');
                    e.innerText = 'Свернуть';
                }
            })
        });
    }

    const showMoreFilters = document.querySelectorAll('.catalog_filter__block__show-more')

    if (showMoreFilters.length > 0) {
        showMoreFilters.forEach((e) => {
            e.addEventListener('click', (el) => {
                const list = el.target.parentNode;
                if (list.classList.contains('active')) {
                    list.classList.remove('active');
                    el.target.innerText = 'Показать больше';
                } else {
                    list.classList.add('active');
                    el.target.innerText = 'Свернуть';
                }
            });
        });
    }

    const showMoreCategory = document.querySelectorAll('.catalog-category_size_basic__info-list__show-more')

    if (showMoreCategory.length > 0) {
        showMoreCategory.forEach((el) => {
            el.addEventListener('click', (evt) => {
                const infoList = evt.target.parentNode;
                infoList.classList.add('active')
            })
        });
    }

    const footerShowMore = document.querySelectorAll('.footer-section__show-more');
    if (footerShowMore.length > 0) {
        footerShowMore.forEach((el) => {
            el.addEventListener('click', (evt) => {
                const infoList = evt.target.parentNode;
                if (infoList.classList.contains('active')) {
                    evt.target.innerHTML = `
                        <a class="button_text_href_transparent">Больше</a>
                    `;
                } else {
                    evt.target.innerHTML = `
                        <a class="button_text_href_transparent">Свернуть</a>
                    `;
                }
                infoList?.classList.toggle('active');
            })
        });
    }

    const showMoreBasket = document.querySelectorAll('.showMoreInBasket');
    if (showMoreBasket.length > 0) {
        showMoreBasket.forEach((el) => {
            el.addEventListener('click', (evt) => {
                const infoList = evt.target.parentNode;
                const findBody = Array.from(infoList.children).find((e) => e.classList.contains('showMoreInfo'));
                if (infoList.classList.contains('active')) {
                    findBody.classList.remove('active');
                    findBody.style.maxHeight = null;               
                } else {
                    findBody.style.maxHeight = findBody.scrollHeight * 2 + "px";   
                    findBody.classList.add('active');
                }
                infoList?.classList.toggle('active');
            })
        });
    }

    const showMoreProductCard = document.querySelector('.show_more_no_characteristic');
    
    if (showMoreProductCard) {
        const showMoreProductDesc = document.querySelector('.product-card-section_desc__description');
        const showMoreProductText = showMoreProductDesc.querySelector('.product-card-section_desc__description__text');
        const showMoreProductBtn = showMoreProductDesc.querySelector('.button_text_href_transparent ');
        showMoreProductCard.addEventListener('click', () => {
            showMoreProductText.style.maxHeight = showMoreProductText.scrollHeight * 2 + "px";   
            showMoreProductText.classList.add('show');
            showMoreProductBtn.innerText = 'Свернуть';
        })
    }
}