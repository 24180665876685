export function productToggle() {
    // data-characteristicId

    const inputs = document.querySelectorAll('[data-characteristicid] input')
    inputs.forEach(input => {
        input.addEventListener('change', combineIds)
    })
}

function combineIds() {
    const inputs = document.querySelectorAll('[data-characteristicid] input:checked');
    const variableCard = document.getElementById('idVariableCard');
    const ids = [];
    let groupId = variableCard.value

    inputs.forEach(input => {
        ids.push(input.parentNode.dataset.characteristicid);
    });
    sendFetch(ids, groupId)
        .then(res => {
            window.location.href = `/product/${res.slug}/`
        })

}

async function sendFetch(ids, groupId) {
    const response = await fetch('/api/products/get-modification/', {
        method: "POST",
        headers: {
            'Content-Type': "application/json",
        },
        body: JSON.stringify({"ids": ids, "group_id": groupId}
        ),
    });

    if (response.ok) return response.json();
    else throw new Error(response.statusText);
}