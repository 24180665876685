import { docOverflowHidden, docOverflowRemove, docOverflowRemoveTimeout } from "./utils.js";

export function header() {
    const stickyHeader = document.getElementById('sticky-header');
    const stickyNav = document.getElementById('page-nav-sticky');

    let prevScrollPos = window.pageYOffset;
    window.onscroll = function() {
        const currentScrollPos = window.pageYOffset;

        if (prevScrollPos > currentScrollPos) {
            stickyHeader.classList.remove('sticky-hidden');
            if (stickyNav) {
                stickyNav.classList.remove('sticky-hidden');
            }
        } else {
            stickyHeader.classList.add('sticky-hidden');
            if (stickyNav) {
                stickyNav.classList.add('sticky-hidden');
            }
        }

        prevScrollPos = currentScrollPos;
    }

    const scrollX = document.querySelectorAll('.scrollX');
    if (scrollX.length > 0) {
        scrollX.forEach((el) => {
            el.addEventListener('mouseover', docOverflowHidden);
            el.addEventListener('mouseout', docOverflowRemove);
            el.addEventListener("wheel", function (e) {
                if (e.deltaY > 0) {
                    el.scrollLeft += 50;
                    el.parentElement.classList.add('left');
                } else {
                    el.scrollLeft -= 50;
                    el.parentElement.classList.remove('left');
                }
            });
        })
    }


    const catalogShowMore = document.querySelectorAll('.catalog_category_size_basic__info-list__show-more');
    catalogShowMore.forEach((e) => {
        const arrUl = Array.from(e.parentNode.children);
        if (arrUl.length <= 4) {
            e.parentNode.classList.add('active')
        };
        e.addEventListener('click', (el) =>{
            const findUl = el.target.parentNode;
            findUl.classList.add('active');
        })
    });

    const inset = document.querySelector('.__inset');
    const openMenuBtn = document.querySelectorAll('.open-some-menu');
    const allMenus = document.querySelectorAll('.some-menu');
    const headerNav = document.getElementById('header__nav');

    openMenuBtn.forEach((el) => {
        const getDataOpen = el.getAttribute('data-open')
        el.addEventListener('click',() => {

            allMenus.forEach((e) => {
                e.style.maxHeight = '0';
                e.style.opacity = 0;
            })
            

            if (el.classList.contains('active')) {
                docOverflowRemoveTimeout()
                headerNav.classList.remove('non-active');
                inset.classList.remove('show');
                el.classList.remove('active');
            } else {
                docOverflowHidden()
                headerNav.classList.add('non-active');
                inset.classList.add('show');
                allMenus.forEach((evt) => {
                    const getMenuData = evt.getAttribute('data-menu');
                    if(getMenuData == getDataOpen) {
                        evt.style.maxHeight = evt.scrollHeight * 2 + "px";
                        evt.style.opacity = 1;
                    }
                })

                openMenuBtn.forEach((e) => {
                    e.classList.remove('active');
                })
                el.classList.add('active');
            }
        })
    })

    if(inset) {
        inset.addEventListener('click', () => {
            docOverflowRemoveTimeout()
            inset.classList.remove('show');
            allMenus.forEach((e) => {
                e.style.maxHeight = '0';
            })
            openMenuBtn.forEach((e) => {
                e.classList.remove('active')
            })
        })
    }

    const getCurrLink = document.querySelectorAll('.get_curr_link');
    if(getCurrLink.length > 0) {
        getCurrLink.forEach((link) => {
            const curPath = window.location.pathname.slice(0, Math.ceil(window.location.pathname.length / 2));
            const curLink = link.href
            if (curLink.includes(curPath)) {
                link.classList.add('curr_link');
            }
        })
    };

    const getCurrCatalog = document.querySelectorAll('.get_curr_catalog');
    if(getCurrCatalog.length > 0) {
        getCurrCatalog.forEach((link) => {
            const curPath = window.location.pathname.slice(0, Math.ceil(window.location.pathname.length / 2));
            if(curPath.includes('catalog')) {
                link.classList.add('curr_link');
            }
        })
    }

    const headerMain = document.querySelector('.header-main');

    if(headerMain) {
        window.addEventListener('scroll', function() {
            if(window.scrollY > 50) {
                headerMain.style.background = '#FFFFFF';
            } else {
                headerMain.style.background = null;
            }
        });
    }

}