import {
    addToBasket,
    removeFromBasket,
    getCookieIndividual,
    userLoggedIn,
} from '../../cookies.js'
import {
    changeBasketWithValue,
    changeCurValue,
    concatenateDigits,
} from '../../basket.js'
import {
    debounce
} from '../../utils.js';
import {changeBubbleValue} from '../../wishlist.js';


const fullPrice = document.getElementById('full_price');
const fullBasicPrice = document.getElementById('full_basic_price');
const fullDiscount = document.getElementById('full_discount');

const basketList = document.getElementById('basket__list');

export function addCard(data, el) {

    let defaultPrice = data.basic_price + '₽';
    let finishPrice = data.price ? `${data.price} ₽` > 0 : "ПОД ЗАКАЗ"
    let slug = data.slug ? `href="/product/${data.slug}"` : '';
    let article = data.sku ? 'Арт. ' + data.sku : '';
    let mainPhoto = data.main_photo ? data.main_photo : '/static/assets/images/img/stub.png';
    if (data.basic_price == data.price) {
        defaultPrice = '';
    }
    const productCard = document.createElement('div');
    const hr = document.createElement('hr');
    productCard.classList.add('product-card', 'product-card_vertical');
    if (data.is_cooler) {
        productCard.innerHTML = `
            <input type="checkbox" class="select_to_delete" value="${data.id}">
            <input type="hidden" class="product__id" value="${data.id}">
            <div class="product-card_vertical__img">
                <input type="hidden" class="product_weight" value="0">
                <div class="img_hex" style="background-color: ${data.hex_code}"></div>
                <a ${slug}></a>
                <button class="button button_icon_small delete_this_product">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 6V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H11.2C10.0799 2 9.51984 2 9.09202 2.21799C8.71569 2.40973 8.40973 2.71569 8.21799 3.09202C8 3.51984 8 4.0799 8 5.2V6M10 11.5V16.5M14 11.5V16.5M3 6H21M19 6V17.2C19 18.8802 19 19.7202 18.673 20.362C18.3854 20.9265 17.9265 21.3854 17.362 21.673C16.7202 22 15.8802 22 14.2 22H9.8C8.11984 22 7.27976 22 6.63803 21.673C6.07354 21.3854 5.6146 20.9265 5.32698 20.362C5 19.7202 5 18.8802 5 17.2V6" stroke="#1C1917" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
            <div class="product-card_vertical__info-block">
                <div class="product-card_vertical__info-block__info">
                    <a ${slug} class="product-card_vertical__cost-block">
                        <div class="product-card_vertical__cost-block__cost cooler_cost">
                            ${data.litters}
                        </div>
                        <div class="product-card_vertical__cost-block__cost_prev">
                            ${defaultPrice}
                        </div>
                    </a>
                    <a ${slug} class="product-card_vertical__title-block">
                        ${data.name}
                    </a>
                    <button class="button button_icon_small delete_this_product">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 6V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H11.2C10.0799 2 9.51984 2 9.09202 2.21799C8.71569 2.40973 8.40973 2.71569 8.21799 3.09202C8 3.51984 8 4.0799 8 5.2V6M10 11.5V16.5M14 11.5V16.5M3 6H21M19 6V17.2C19 18.8802 19 19.7202 18.673 20.362C18.3854 20.9265 17.9265 21.3854 17.362 21.673C16.7202 22 15.8802 22 14.2 22H9.8C8.11984 22 7.27976 22 6.63803 21.673C6.07354 21.3854 5.6146 20.9265 5.32698 20.362C5 19.7202 5 18.8802 5 17.2V6" stroke="#1C1917" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
                <div class="product-card_vertical__article-block">
                    <div class="painting-section__pallet-block__square-block">
                        <div>Площадь фасада (м³)</div>
                        <label for="square" class="input-text input-text_size_basic">
                            <input type="text" class="square" id="square" value="${data.area}" placeholder="${data.area}" maxlength="3">
                        </label>
                        <div id="" class="custom-range slider"></div>
                    </div>
                </div>
            </div>
        `
    } else {
        productCard.innerHTML = `
            <input type="checkbox" class="select_to_delete" value="${data.id}">
            <div class="product-card_vertical__img">
                <input type="hidden" class="product_weight" value="${data.weight}">
                <img loading="lazy" src="${mainPhoto}">
                <a ${slug}></a>
                <button class="button button_icon_small delete_this_product">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 6V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H11.2C10.0799 2 9.51984 2 9.09202 2.21799C8.71569 2.40973 8.40973 2.71569 8.21799 3.09202C8 3.51984 8 4.0799 8 5.2V6M10 11.5V16.5M14 11.5V16.5M3 6H21M19 6V17.2C19 18.8802 19 19.7202 18.673 20.362C18.3854 20.9265 17.9265 21.3854 17.362 21.673C16.7202 22 15.8802 22 14.2 22H9.8C8.11984 22 7.27976 22 6.63803 21.673C6.07354 21.3854 5.6146 20.9265 5.32698 20.362C5 19.7202 5 18.8802 5 17.2V6" stroke="#1C1917" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
            <div class="product-card_vertical__info-block">
                <div class="product-card_vertical__info-block__info">
                    <a ${slug} class="product-card_vertical__cost-block">
                        <div class="product-card_vertical__cost-block__cost">
                            ${finishPrice}
                        </div>
                        <div class="product-card_vertical__cost-block__cost_prev">
                            ${defaultPrice}
                        </div>
                    </a>
                    <a ${slug} class="product-card_vertical__title-block">
                        ${data.name}
                    </a>
                    <button class="button button_icon_small delete_this_product">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 6V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H11.2C10.0799 2 9.51984 2 9.09202 2.21799C8.71569 2.40973 8.40973 2.71569 8.21799 3.09202C8 3.51984 8 4.0799 8 5.2V6M10 11.5V16.5M14 11.5V16.5M3 6H21M19 6V17.2C19 18.8802 19 19.7202 18.673 20.362C18.3854 20.9265 17.9265 21.3854 17.362 21.673C16.7202 22 15.8802 22 14.2 22H9.8C8.11984 22 7.27976 22 6.63803 21.673C6.07354 21.3854 5.6146 20.9265 5.32698 20.362C5 19.7202 5 18.8802 5 17.2V6" stroke="#1C1917" stroke-opacity="0.4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
                <div class="product-card_vertical__article-block">
                    <a ${slug} class="product-card_vertical__article-block__article">
                        ${article}
                    </a>
                    <button class="add_to_basket">
                        <input type="hidden" class="product__id" value="${data.id}">
                    </button>
                    <button class="button button_counter_basic">
                        <svg class="change_counter_value minus" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 12H19" stroke="#1C1917" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <input type="text" value="${el['quantity']}">
                        <svg class="change_counter_value plus" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 5V19M5 12H19" stroke="#1C1917" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </div>
            </div>
        `
    }

    // Изменение финальной цены в зависимости от кол-ва и стоимости продуктов

    const weight = productCard.querySelector('.product_weight');
    const price = productCard.querySelector('.product-card_vertical__cost-block__cost');
    const basicPrice = productCard.querySelector('.product-card_vertical__cost-block__cost_prev');

    const parseWeight = concatenateDigits(weight.value)
    const parsePrice = concatenateDigits(price.innerText);
    const parseBasicPrice = concatenateDigits(basicPrice.innerText);


    let discount = '';
    if (parseBasicPrice !== '') discount = parseBasicPrice - parsePrice;


    const coolerPrice = productCard.querySelector('.cooler_cost');
    if (coolerPrice) {
        fullBasicPrice.innerText = 'Цена по запросу';
        fullPrice.innerText = 'Цена по запросу';
    }

    // Изменение счётчика

    const addBtn = productCard.querySelector('.add_to_basket');
    const input = productCard.querySelector('.button_counter_basic').querySelector('input');
    input.addEventListener('input', () => {
        input.value = input.value.replace(/\D/g, '');
        debouncedChangeWithValue(addBtn);
    })

    const productId = productCard.querySelector('.product__id');
    const changeBtnsCard = productCard.querySelectorAll('.change_counter_value');
    changeBtnsCard.forEach((btn) => {
        const findInput = Array.from(btn.parentNode.children).find(el => el.localName == 'input');
        const findAddBtn = Array.from(btn.parentNode.parentNode.children).find(el => el.classList.contains('add_to_basket'));
        Array.from(getCookieIndividual())
            .forEach((el) => {
                if (el.product == productId.value) {
                    findAddBtn.classList.add('in-basket');
                    findInput.value = el.quantity;
                }
            })
        btn.addEventListener('click', () => {
            let inputVal = parseInt(findInput.value);
            if (btn.classList.contains('plus')) {
                inputVal += 1;
                changeBubbleValue('basket', 'plus')
            } else if (btn.classList.contains('minus')) {
                inputVal -= 1;
                if (inputVal <= -1) {

                } else {
                    changeBubbleValue('basket')
                }
            }
            removeFromBasket(Number(productId.value));
            findInput.value = inputVal >= 0 ? inputVal : 0;
            if (inputVal <= 0) {
                findAddBtn.classList.remove('in-basket');
            } else {
                addToBasket(Number(productId.value), Number(findInput.value));
            }


            changeCurValue()
        });
    });

    // Удаление одного товара

    const deleteBtn = productCard.querySelectorAll('.delete_this_product');

    deleteBtn.forEach((btn) => {
        btn.addEventListener('click', () => {
            removeFromBasket(Number(productId.value));
            basketList.removeChild(productCard);
            basketList.removeChild(hr);
            window.location.reload();
        })
    })

    // Функционал для колеровки красок

    const productSlider = productCard.querySelector('.slider');
    const productSquare = productCard.querySelector('.square');


    $(function () {
        $(productSlider).slider({
            range: "min",
            max: 999,
            slide: function (event, ui) {
                $(productSquare).val(ui.value);
                debouncedCalculateTotalPrice(productSquare, data.palette_id, price);
                sendArea(productCard, ui);
            }
        });

        $(productSquare).val($(productSlider).slider("value"));

        $(productSquare).on("input", function () {
            let value = $(this).val();
            $(productSlider).slider("value", value);

            debouncedCalculateTotalPrice(productSquare, data.palette_id, price);
            sendArea(productCard, ui);
        });

        setTimeout(() => {
            if (productSquare) {
                productSquare.value = data.area;
                $(productSlider).slider("value", data.area);
            }
        }, 150);
    });


    // return productCard;

    function getValues() {
        return {
            productCard: productCard,
            hr: hr,
        }
    }

    return getValues();
}

async function getPalletPrice(paletteId, square) {
    const response = await fetch(
        `/api/pallets/${paletteId}/calculate-price/?square=${square}`,
        {
            "method": "GET"
        }
    );
    if (response.ok) return response.json();
    else throw new Error(response.statusText);
}

export async function calculateTotalPriceLocal(squareEl, PaletteId, priceBlock) {
    const square = squareEl.value ? squareEl.value : 0;

    const price = await getPalletPrice(PaletteId, square);
    fullPrice.innerText = parseInt(fullPrice.innerText) - parseInt(priceBlock.innerText);
    priceBlock.innerText = `${price.price}`;
    fullPrice.innerText = parseInt(fullPrice.innerText) + parseInt(priceBlock.innerText);
    fullBasicPrice.innerText = parseInt(fullPrice.innerText) - parseInt(fullDiscount.innerText);
}

const debouncedCalculateTotalPrice = debounce(calculateTotalPriceLocal, 750);

export function sendArea(productCard, ui) {
    const id = productCard.querySelector('.select_to_delete').value;
    let url = '/api/products/' + `${id}/`;
    let body = {};
    body.area = ui.value;
    userLoggedIn() ? debouncedSendForm(body, url, userLoggedIn()) : debouncedSendForm(body, url);
}

export async function sendFormPath(data, url, token) {
    const csrfToken = $('[name=csrfmiddlewaretoken]').val();
    let response = '';
    if (token) {
        response = await fetch(url, {
            method: "PATCH",
            headers: {
                'Content-Type': "application/json",
                'X-CSRFToken': csrfToken,
                'Authorization': 'Token ' + token,
            },
            body: JSON.stringify(data),
        });
    } else {
        response = await fetch(url, {
            method: "PATCH",
            headers: {
                'Content-Type': "application/json",
                'X-CSRFToken': csrfToken
            },
            body: JSON.stringify(data),
        });
    }
    if (response.ok) return response.json();
    else throw new Error(response.statusText);
};

const debouncedSendForm = debounce(sendFormPath, 750);

const debouncedChangeWithValue = debounce(changeBasketWithValue, 500);