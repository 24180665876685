import { userLoggedIn } from './cookies.js'
import { addArticlesCards } from './pages/articles/add-cards.js';
import { AddCatalogCards } from './pages/catalog/add-cards.js';
import { AddFaqCards } from './pages/faq/add-card.js';
import { AddReviewCards } from './pages/reviews/add-card.js';
import { addQuery } from './utils.js';

export function pagination() {

    changePage();
  


    const loadMoreBtn = document.querySelectorAll('.load-more-pagination');
    if(loadMoreBtn.length > 0) {
        loadMoreBtn.forEach((btn) => {
            let curPath = window.location.pathname;
            if(curPath.includes('/ready-solutions/')) btn.style.display = 'none';
            btn.addEventListener('click',() => {
                const search = window.location.search;

                if(curPath.includes('/reviews/')) {
                    let url = 'reviews-list/';

                    const reviewsList = document.querySelector('.about-us-reviews__container__info-block__list');
                    getPagination(changePageVal(url))
                        .then(data => AddReviewCards(data, reviewsList))
                }

                if(curPath.includes('/articles/')) {
                    let url = 'articles/';
                    
                    const articlesList = document.querySelector('.about-us-article__container__info-block__list');
                    getPagination(changePageVal(url))
                        .then(data => addArticlesCards(data, articlesList))
                }

                if(curPath.includes('/faq/')) {
                    let url = 'faq/';
                    
                    const faqList = document.querySelector('.about-us-faq__container__accordion_block__list');
                    getPagination(changePageVal(url))
                        .then(data => AddFaqCards(data,faqList))
                }

                if(curPath.includes('/catalog/')) {
                    let url = 'products/';
                    const catalogSlug = curPath.split('/catalog/')[1].replace('/', '');
                    url = changePageVal(url) + '&category=' + catalogSlug;


                    const catalogList = document.querySelector('.catalog-section__products-list__list');
                    if(userLoggedIn()) {
                        getPaginationLoggedIn(url)
                            .then(data => AddCatalogCards(data,catalogList));   
                    } else {
                        getPagination(url)
                            .then(data => AddCatalogCards(data,catalogList));   
                    }
                }
                
                if(curPath.includes('/brands/')) {
                    let url = 'products/';
                    const brandSlug = curPath.split('/brands/')[1].replace('/', '');
                    url = changePageVal(url) + '&brand=' + brandSlug;
                    
                    const catalogList = document.querySelector('.catalog-section__products-list__list');
                    if(userLoggedIn()) {
                        getPaginationLoggedIn(url)
                            .then(data => AddCatalogCards(data,catalogList));   
                    } else {
                        getPagination(url)
                            .then(data => AddCatalogCards(data,catalogList));   
                    }
                }
            })
        })
    }
}




function changePage() {
    const paginationEl = document.querySelectorAll('.pagination-elem');

    if (paginationEl.length > 0) {
        paginationEl.forEach((el) => {
            el.addEventListener('click', (e) => {
                const attrPage = e.target.getAttribute('data-page');
                if(attrPage) addQuery('page', attrPage);
            });
        });
    };
}

async function getPagination(url) {
    const response = await fetch('/api/' + url, {
        method: "GET",
    });
    if(response.ok) return response.json();
    else throw new Error(response.statusText);
};

async function getPaginationLoggedIn(url) {
    const response = await fetch('/api/' + url, {
        method: "GET",
        'Authorization': 'Token ' + userLoggedIn(),
    });
    if(response.ok) return response.json();
    else throw new Error(response.statusText);
};

export function noPaginationBlock() {
    const paginationSection = document.querySelector('.pagination__section');
    paginationSection.style.display = 'none';
};

function changePageVal(url) {
    const href = new URL(window.location.href);
    let pageValue = href.searchParams.get('page');
    if(pageValue !== null) {
        pageValue = parseInt(pageValue) + 1;
        href.searchParams.set('page', pageValue.toString());
    } else {
        href.searchParams.set('page', 2);
    }
    if (href.search) url = url + href.search;
    history.replaceState({}, '', href.search.toString());
    return url;
};