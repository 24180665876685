import { appendCard, changeBtnsStates, checkValueGetState, contextualMenu, deleteCard, validateToSave } from "../../technicalPage.js";

export function addCardTechnical(data, list) {
    const fragment = document.createDocumentFragment();
    data.results.forEach((el) => {
        const card = addCard(el);

        const technicalScene = document.querySelectorAll('.technical-section__main-scene');

        // Нажатие на карточки справа
        const addBtn = card.querySelector('.add_new_product');

        if (addBtn) {
            const btnDataId = addBtn.getAttribute('data-id');
            technicalScene.forEach(scene=>{
                const placeMarks = scene.querySelectorAll('.placemark_setted');
                placeMarks.forEach(mark=>{
                    const markDataId = mark.getAttribute('data-id');
                    if(btnDataId == markDataId) addBtn.classList.add('active');
                });
            });
            addBtn.addEventListener('click', () => {
                const placeMark = document.querySelector('.placemark_new'); // Получаем новую точку
                const btnId = addBtn.getAttribute('data-id');
                if (addBtn.classList.contains('active')) {
                    // Если активная то удаляем карточку с таким же айди в сцене
                    deleteCard(addBtn);
                } else {
                    // Если кнопка неактивная и нет новой точки
                    // то идём ко второму сценарию
                    if (placeMark) {
                        const mainScene = placeMark.parentElement; // Получаем сцену
                        const arrScene = Array.from(mainScene.children)
                    } else {
                        const addBtns = document.querySelectorAll('.add_new_product');
                        addBtns.forEach((btn) => {
                            btn.classList.remove('add_new_product_disabled');
                        })
                        addBtn.classList.add('add_new_product_disabled');
                        contextualMenu(addBtn);
                    }
                }

                if (placeMark) {
                    const mainScene = placeMark.parentElement; // Получаем сцену
                    // Если есть новая точка в документе(1 сценарий)
                    addBtn.classList.add('active');
                    changeBtnsStates();
                    // Создаём карточку
                    appendCard(placeMark, card);
                    // Возвращаем возможность добавлять точки на сцену
                    mainScene.style.pointerEvents = 'auto';
                }
                validateToSave();
            })
        }

        // Третий сценарий(drag-n-drop)

        const fakeCard = document.querySelector('.product-card_size_micro_fake');
        const fakeImg = fakeCard.querySelector('img');
        const fakePrice = fakeCard.querySelector('.product-card_size_micro__cost-block__cost');
        const fakeBasicPrice = fakeCard.querySelector('.product-card_size_micro__cost-block__cost_prev');
        const fakeTitle = fakeCard.querySelector('.product-card_size_micro__title');

        card.onmousedown = (e) => {
            if (!e.target.classList.contains('add_new_product')) {
                const cardBtn = card.querySelector('.add_new_product')
                const idBtn = cardBtn.getAttribute('data-id')
                fakeImg.src = card.querySelector('img').src
                fakeTitle.innerText = card.querySelector('.product-card_technical__title-block__title').innerText
                fakePrice.innerText = card.querySelector('.price').value
                if (card.querySelector('.price_basic')) {
                    fakeBasicPrice.innerText = card.querySelector('.price_basic').value;
                }


                fakeCard.style.display = 'flex';
                fakeCard.style.position = 'absolute';
                document.body.appendChild(fakeCard);
                moveAt(e);

                fakeCard.style.zIndex = 1000;

                function moveAt(e) {
                    fakeCard.style.left = e.pageX + 'px';
                    fakeCard.style.top = e.pageY + 'px';
                }

                document.onmousemove = (e) => {
                    moveAt(e);
                };


                document.onmouseup = (evt) => {
                    if (fakeCard.style.display == 'flex') {
                        if (evt.target.classList.contains('technical-section__main-scene')) {
                            cardBtn.classList.add('active')
                            const coords = evt.target.getBoundingClientRect()
                            const xPercent = ((evt.clientX - coords.left) / coords.width) * 100;
                            const yPercent = ((evt.clientY - coords.top) / coords.height) * 100;
                            const placeMark = document.createElement('div');
                            placeMark.style.top = yPercent + '%';
                            placeMark.style.left = xPercent + '%';
                            checkValueGetState(xPercent, 'directionX', placeMark);
                            checkValueGetState(yPercent, 'directionY', placeMark);

                            placeMark.classList.add('placemark_setted');
                            placeMark.setAttribute("data-id", idBtn);
                            placeMark.innerHTML = `
                                <div class="placemark_setted__btn">
                                </div>
                                <a class="product-card product-card_size_micro">
                                    <div class="product-card_size_micro__img">
                                        <img loading="lazy" src="${fakeImg.src}">
                                    </div>
                                    <div class="product-card_size_micro__title-block">
                                        <div class="product-card_size_micro__cost-block">
                                            <div class="product-card_size_micro__cost-block__cost">
                                                ${fakePrice.innerText} ₽
                                            </div>
                                            <div class="product-card_size_micro__cost-block__cost_prev">
                                                ${fakeBasicPrice.innerText} 
                                            </div>
                                        </div>
                                        <div class="product-card_size_micro__title">
                                            ${fakeTitle.innerText}
                                        </div>
                                        <button class="button button_text_small delete-placemark">
                                            Удалить
                                        </button>
                                    </div>
                                </a>
                            `

                            const settedBtn = placeMark.querySelector('.placemark_setted__btn');
                            const deleteBtn = placeMark.querySelector('.delete-placemark');
                            settedBtn.addEventListener('click', () => {
                                (placeMark.classList.contains('active')) ? placeMark.classList.remove('active') : placeMark.classList.add('active');
                            });
                            deleteBtn.addEventListener('click', () => {
                                if (placeMark.classList.contains('placemark_setted')) {
                                    deleteCard(deleteBtn, placeMark);
                                };
                            });
                            const arrScene = Array.from(evt.target.children)
                            if (arrScene.length > 0) {
                                evt.target.appendChild(placeMark);
                            } else {
                                evt.target.appendChild(placeMark);
                            }
                        }
                        validateToSave()
                    }
                    document.onmousemove = null;
                    fakeCard.onmouseup = null;
                    fakeCard.style.display = 'none';
                };
            }
            ;
        };

        card.ondragstart = function () {
            return false;
        };

        // Добавление элементов во фрагмент

        fragment.appendChild(card);

        const hr = document.createElement('hr');
        fragment.appendChild(hr);
    })


    list.appendChild(fragment);
}

function addCard(el) {
    let dataImage = el['main_photo'] ? el['main_photo'] : '/static/assets/images/img/stub.png';
    const newReview = document.createElement('div');
    newReview.classList.add('product-card', 'product-card_technical');
    newReview.innerHTML = `
        <input type="hidden" class="price_basic" value="${el['basic_price']}">
        <input type="hidden" class="price" value="${el['price']}">
        <div class="product-card_technical__img">
        <img src="${dataImage}">
        </div>
        <div class="product-card_technical__info-block">
            <div class="product-card_technical__title-block">
                <div class="product-card_technical__title-block__title">
                    ${el['name']}
                </div>
                <div class="product-card_technical__title-block__title_sub">
                    Арт. ${el['sku']}
                </div>
            </div>
            <div class="product-card_technical__chars-block">
                <div class="product-card_technical__chars-block__char">
                    <span>Вес:</span>
                    ${el['weight']} кг
                </div>
            </div>
        </div>
        <button class="button button_icon_large add_new_product"
                data-id="${el['id']}">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M15.9974 6.66666V25.3333M6.66406 16H25.3307" stroke="white"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
    `;
    return newReview;
}
