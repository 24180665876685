export function AddReviewCards(data,list) {
    const fragment = document.createDocumentFragment();
    data.results.forEach((el) => {
        const newReview = addCard(el);

        fragment.appendChild(newReview);

        const hr = document.createElement('hr');
        fragment.appendChild(hr);
    })
    list.appendChild(fragment);
    if(!data.next) {
        noPaginationBlock()
    }
}

function addCard(el) {
    let dataAvatar = el['avatar'] ? el['avatar'] : '/static/assets/images/img/stub.png';
    const newReview = document.createElement('div');
    newReview.classList.add('testimonial-card');
    newReview.innerHTML = `
        <div class="testimonial-card__info-block">
            <div class="testimonial-card__info-block__icon">
                <img loading="lazy" src="${dataAvatar}">
            </div>
            <div class="testimonial-card__title-block">
                <div class="testimonial-card__title-block__title">
                    ${el['first_name']}  ${el['last_name']}
                </div>
                <div class="testimonial-card__title-block__date">
                    ${el['date_create']}
                </div>
            </div>
        </div>
        <div class="testimonial-card__text-block">
            ${el['review']}
        </div>
    `
    return newReview;
}
