import { 
    setCookie,
    userLoggedIn
} from "./cookies.js";
import { sendFormWithToken } from "./validation.js";

export function logout() {
    const logoutBtn = document.querySelectorAll('.logout__btn');

    if(logoutBtn) {
        logoutBtn.forEach((btn) => {
            btn.addEventListener('click',() => {
                let curToken = getTokenFromCookie();
                sendFormWithToken(NaN, '/api/logout/');
                setTimeout(() => {
                    setCookie('token', curToken, { secure: true, 'max-age': -1 });
                    window.location.reload();
                }, 150);
            })
        })
    };
}


function getTokenFromCookie() {
    const tokenCookies = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/, "$1")
    return tokenCookies ? tokenCookies : null;
}