export function modifications() {
    const scrollableMods = document.querySelectorAll('.product-card-section__info-block__card__select-block');
    const openModalBtn = document.querySelector('.modsModal')
    if(scrollableMods.length > 0) {
        scrollableMods.forEach((mod) => {
            if (mod.scrollWidth > mod.clientWidth) {
                openModalBtn.style.display = 'flex';
            }
        })
    }
    const showMoreChar = document.querySelector('.showMoreChar');
    const charList = document.querySelector('.product-card-section_desc__characteristic__list');
    if(charList) {
        const arr = Array.from(charList.children);
        if(arr.length <= 8) {
            showMoreChar.parentNode.removeChild(showMoreChar);
        }
    }
    const showMoreDesc = document.querySelector('.showMoreDesc');
    const descArea = document.querySelector('.product-card-section_desc__description__text');
    if(descArea) {
        const style = window.getComputedStyle(descArea);
        if(parseInt(style.maxHeight) > parseInt(descArea.scrollHeight)) showMoreDesc.parentNode.removeChild(showMoreDesc);
    }
}