import { debounce } from "./utils.js";

export function turkneyCard() {

    const turkneyCardsLarge = document.querySelectorAll('.turkney-card_large');
    if(turkneyCardsLarge.length > 0) {
        turkneyCardsLarge.forEach(card=> {
            const cardDot = card.querySelectorAll('.product-info__dot');
            const cardInfo = card.querySelectorAll('.product-info');
            const closeCard = card.querySelectorAll('.close__card');

            cardDot.forEach((e) => {
                e.addEventListener('click', (el) => {
                    const card = el.target.parentNode;
                    if (el !== el.target) {
                        cardInfo.forEach((info) => {
                            info.classList.remove('active');
                            info.classList.remove('display');
                            info.style.zIndex = '1';
                        })
                    };
                    card.classList.add('display');
                    card.style.zIndex = '10';
                    setTimeout(() => {
                        card.classList.add('active')
                    }, 100);
                })
            });
            closeCard.forEach((e) => {
                e.addEventListener('click', (el) => {
                    if (el.target.classList.contains('close__card')) {
                        cardInfo.forEach((info) => {
                            info.classList.remove('active');
                            info.classList.remove('display');
                            info.style.zIndex = '1';
                        })
                    }
                })
            });
        })
    }

    const turkeyCardsInteractive = document.querySelectorAll('.turkney-card_interactive__info-block');
    if(turkeyCardsInteractive.length > 0) {
        turkeyCardsInteractive.forEach(card=> {
            const cardDot = card.querySelectorAll('.product-info__dot');
            const cardInfo = card.querySelectorAll('.product-info');
            const closeCard = card.querySelectorAll('.close__card');
            const sameCard = card.querySelectorAll('.turkney-card_interactive__same-card');
            setDisplayedChild()
            let displayedCards = {};
            sameCard.forEach((card) => {
                const sameCardID = card.getAttribute('data-id');
                if(!displayedCards[sameCardID]) {
                    card.style.display = 'flex';
                    displayedCards[sameCardID] = true;
                } else {
                    card.parentElement.removeChild(card)
                }
            });

            
            cardDot.forEach((e) => {
                e.addEventListener('click', (el) => {
                    const card = el.target.parentNode;
                    const dataId = el.target.getAttribute('data-id');
                    if (el !== el.target) {
                        cardInfo.forEach((info) => {
                            info.classList.remove('active');
                            info.classList.remove('display');
                            info.style.zIndex = '1';
                        })
                    };
                    sameCard.forEach((evt) => {
                        const dataIdCard = evt.getAttribute('data-id');
                        if(dataIdCard == dataId) {
                            evt.classList.add('active');
                            evt.classList.remove('non-active');
                        } else {
                            evt.classList.remove('active');
                            evt.classList.add('non-active');
                        }
                    })
                    card.classList.add('display');
                    card.style.zIndex = '10';
                    setTimeout(() => {
                        card.classList.add('active')
                    }, 100);
                })
            });
            closeCard.forEach((e) => {
                e.addEventListener('click', (el) => {
                    if (el.target.classList.contains('close__card')) {
                        cardInfo.forEach((info) => {
                            info.classList.remove('active');
                            info.classList.remove('display');
                            info.style.zIndex = '1';
                        })
                        sameCard.forEach((evt) => {
                            evt.classList.remove('non-active');
                            evt.classList.remove('active');
                        })
                    }
                })
            });
            sameCard.forEach((el) => {
                const id = el.getAttribute('data-id');
                el.addEventListener('mouseover',() => {
                    sameCard.forEach((card) => {
                        card.classList.add('non-active');
                    });
                    el.classList.remove('non-active');
                    el.classList.add('active');
                    cardDot.forEach((el) => {
                        const elId = el.getAttribute('data-id');
                        if(elId == id) {
                            el.parentElement.classList.add('display', 'active');
                            el.parentElement.style.zIndex = '10';
                        }
                    })
                });
                el.addEventListener('mouseout',() => {
                    sameCard.forEach((card) => {
                        card.classList.remove('non-active');
                        card.classList.remove('active');
                    })
                    cardDot.forEach((el) => {
                        el.parentElement.style.zIndex = '';
                        el.parentElement.classList.remove('display', 'active')
                    })
                });
            });
        })
    }

    const indexReadySolutions = document.querySelectorAll('.turkney-card_large__image');
    if(indexReadySolutions.length > 0) {
        indexReadySolutions.forEach((solution) => {
            const cards = Array.from(solution.children);
            cards.forEach((card) => {
                const topPos = parseInt(card.style.top);
                const leftPos = parseInt(card.style.left);
                checkValueGetState(topPos, 'directionY', card)
                checkValueGetState(leftPos, 'directionX', card)
            })
        })
    };

    const readySolutionsPageSolutions = document.querySelectorAll('.turkney-card__slider__slide');
    if(readySolutionsPageSolutions.length > 0) {
        readySolutionsPageSolutions.forEach((solution) => {
            const cards = Array.from(solution.children);
            cards.forEach((card) => {
                const topPos = parseInt(card.style.top);
                const leftPos = parseInt(card.style.left);
                checkValueGetState(topPos, 'directionY', card)
                checkValueGetState(leftPos, 'directionX', card)
            })
        })
    };

}

function checkValueGetState(value, direction, placeMark) {
    if (value > 50 && direction == 'directionX') {
        placeMark.classList.add('right');
    }
    if (value > 50 && direction == 'directionY') {
        placeMark.classList.add('bottom');
    }
};

export function setDisplayedChild() {
    const turkeyCardsInteractive = document.querySelectorAll('.turkney-card_interactive__info-block');
    setTimeout(() => {
        turkeyCardsInteractive.forEach(card=>{
            const activeSlide = card.querySelector('.swiper-slide-active');
            const slideChilds = Array.from(activeSlide.children);
            const sameCard = card.querySelectorAll('.turkney-card_interactive__same-card');
            let cardsInSlideId = [];
            slideChilds.forEach(child=> {
                const cardsInSlide = Array.from(child.children)
                .find(el=> el.classList.contains('turkney-card_interactive__product-info__dot'));

                cardsInSlideId.push(cardsInSlide.getAttribute('data-id'));
            });
            let displayedCards = {};
            sameCard.forEach(item => {
                const dataID = item.getAttribute('data-id');
                cardsInSlideId.forEach(num=> {
                    if(dataID == num  && !displayedCards[dataID]) {
                        item.style.opacity = '1';
                        item.style.pointerEvents = 'all';
                        displayedCards[dataID] = true;
                    }
                })
                if(!displayedCards[dataID]) {
                    item.style.opacity = '.4';
                    item.style.pointerEvents = 'none';
                }
            });
        })
    }, 100);
}

