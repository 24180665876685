import {
    removeFromBasket,
    userLoggedIn
} from "./cookies.js";
import {
    sendFormPatch,
    sendFormWithToken,
    sendForm,
} from './validation.js';

export function order() {

    // Пересылка на страницу оформления заказа

    const moveToOrderBtn = document.getElementById('move-to-order');
    if(moveToOrderBtn) {
        moveToOrderBtn.addEventListener('click',() => {
            const checkboxes = document.querySelectorAll('.select_to_delete');
            let data = [];
            checkboxes.forEach((el) => {
                if(el.checked) {
                    let productData = {};
                    const quantityVal = el.parentElement.querySelector('input[type="text"]').value;
                    const productId = el.value;
                    productData['quantity'] = quantityVal;
                    productData['product'] = productId;
                    data.push(productData);
                }
            });
            if(userLoggedIn()) {
                sendFormWithToken({ products: data }, '/api/order/move-to-order/').then(res=>{
                    const newId = res.order_id;
                    window.location.href = '/order/' + newId;
                });
            } else {
                sendForm({ products: data }, '/api/order/move-to-order/').then(res=>{
                    const newId = res.order_id;
                    window.location.href = '/order/' + newId;
                });
            };
        });
    };
    const makeOrderBtn = document.getElementById('make-order-btn');
    if(makeOrderBtn) {
        makeOrderBtn.addEventListener('click',() => {
            // const productsQuantity = document.querySelectorAll('.product__quantity');
            const productsId = document.querySelectorAll('.product__id');
            const saveInProfile = document.getElementById('save_in_profile');
            if(saveInProfile) {
                if(saveInProfile.checked) {
                    let userData = new FormData();
                    const addressVal = document.getElementById('address').value;
                    const lastNameVal = document.getElementById('last_name').value;
                    const firstNameVal = document.getElementById('first_name').value;
                    const phoneVal = document.getElementById('phone').value;
                    const emailVal = document.getElementById('email').value;
                    if(addressVal) userData.append('address', addressVal);
                    if(lastNameVal) userData.append('last_name', lastNameVal);
                    if(firstNameVal) userData.append('first_name', firstNameVal);
                    if(phoneVal) userData.append('phone', phoneVal);
                    if(emailVal) userData.append('email', emailVal);
                    sendFormPatch(userData, '/api/users/update-user-info/', userLoggedIn());
                };
            };
            productsId.forEach((el) => {
                let productData = {};
                productData['product'] = el.value;
                if(userLoggedIn()) {
                    sendFormWithToken(productData, '/api/basket/remove/');
                } else {
                    removeFromBasket(el.value);
                }
            });
        });
    };

    if(window.location.pathname.includes('/order/')) {
        const addressInput = document.getElementById('address');
        if(addressInput) {
            let event = new Event('input', { bubbles: true });
            addressInput.value = addressInput.value;
            addressInput.dispatchEvent(event);
        }
        const deliveryBtn = document.getElementById('delivery_choose_btn');
        const pickupBtn = document.getElementById('pickup_choose_btn');
        
        const form = document.querySelector('.basket-order-section__obtaining__form');
        const card = document.querySelector('.basket-order-section__obtaining__address-card');

        deliveryBtn.addEventListener('click',()=>{
            form.style.display = 'grid';
            card.style.display = 'none';

            deliveryBtn.classList.remove('button_text_basic_transparent');
            deliveryBtn.classList.add('button_text_basic_accent');

            pickupBtn.classList.add('button_text_basic_transparent');
            pickupBtn.classList.remove('button_text_basic_accent');

            let event = new Event('input', { bubbles: true });
            addressInput.value = '';
            addressInput.dispatchEvent(event);
        });
        pickupBtn.addEventListener('click',()=>{
            form.style.display = 'none';
            card.style.display = 'flex';

            pickupBtn.classList.add('button_text_basic_accent');
            pickupBtn.classList.remove('button_text_basic_transparent');

            deliveryBtn.classList.remove('button_text_basic_accent');
            deliveryBtn.classList.add('button_text_basic_transparent');
            dispatchEventAddress();
        });

        const newCity = document.querySelectorAll('.modal-content__background-order__city');
        newCity.forEach(city=>{
            city.addEventListener('click',()=>{

                const time = city.getAttribute('data-time');
                const url = city.getAttribute('data-map-url');

                card.querySelector('.basket-order-section__obtaining__address-card__title-block__title_sub').innerText = time;
                card.querySelector('.basket-order-section__obtaining__button-block').querySelector('.button_outline').href = url;

                newCity.forEach(el=>{
                    el.classList.remove('current');
                });

                city.classList.add('current');
                card.querySelector('#pickup_address').innerText = city.innerText;
                const allModals = document.querySelectorAll(".modal");
                allModals.forEach((el) => {
                    el.attributes["data-modal-state"].value = "close";
                });
                document.documentElement.style.overflow = null;
                dispatchEventAddress();
            });
        });

    }

    const repeatOrderBtns = document.querySelectorAll('.repeat_order_btn');
    if (repeatOrderBtns.length > 0) {
        repeatOrderBtns.forEach((btn) => {
            btn.addEventListener('click',() => {
                const orderId = btn.getAttribute('data-id');
                sendForm(NaN, '/api/order/' + orderId + '/repeat-order/')
                    .then(data => {
                        console.log(data);
                        window.location.pathname = data['url'];
                    })
            })
        })
    }
};

function dispatchEventAddress() {
    const addressInput = document.getElementById('address');
    const pickupAddress = document.getElementById('pickup_address');
    let event = new Event('input', { bubbles: true });
    addressInput.value = pickupAddress.innerText;
    addressInput.dispatchEvent(event);
}