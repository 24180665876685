import { If } from "three/examples/jsm/nodes/Nodes.js";
import { addCardTechnical } from "./pages/technicalPage/add-card.js";
import { debounce } from "./utils.js";

export function technicalPage() {

    const scrollX = document.querySelector('.categories_scrollX');
    if (scrollX) {
        scrollX.addEventListener("wheel", function (e) {
            if (e.deltaY > 0) {
                scrollX.scrollLeft += 50;
                scrollX.parentNode.classList.add('left');
            } else {
                scrollX.scrollLeft -= 50;
                scrollX.parentNode.classList.remove('left');
            }
        });
    };


    document.addEventListener('DOMContentLoaded',() => {
        // Добавления класса active к тем точкам, которые уже есть в сцене
        const placeMarks = document.querySelectorAll('.placemark_setted');
        const addBtns = document.querySelectorAll('.add_new_product');
        let btnsId = [];
        if(addBtns.length > 0) {
            addBtns.forEach((el) => {
                const btnId = el.getAttribute('data-id');
                btnsId.push(btnId);
            });
        };
        if(placeMarks.length > 0) {
            placeMarks.forEach((placeMark) => {
                const markId = placeMark.getAttribute('data-id');
                if(btnsId.includes(markId)) {
                    addBtns.forEach((btn) => {
                        const btnId = btn.getAttribute('data-id');
                        if(btnId == markId) {
                            btn.classList.add('active');
                        };
                    });
                };
            });
        };
    });

    const technicalScene = document.querySelectorAll('.technical-section__main-scene');
    if (technicalScene.length > 0) {

        function init() {
            //Инициализация точек, если они уже установлены
            const placeMarks = document.querySelectorAll('.placemark_setted');
            placeMarks.forEach(placeMark => {
                const settedBtn = placeMark.querySelector('.placemark_setted__btn');
                const deleteBtn = placeMark.querySelector('.delete-placemark');
                settedBtn.addEventListener('click', () => {
                    (placeMark.classList.contains('active')) ? placeMark.classList.remove('active') : placeMark.classList.add('active');
                });
                deleteBtn.addEventListener('click', () => {
                    if (placeMark.classList.contains('placemark_setted')) {
                        deleteCard(deleteBtn, placeMark);
                    };
                });
            });

        }

        init()


        const productsList = document.querySelector('.technical-section__products-block__list');

        getForm(`/api/technical-products/`)
            .then(response=>{
                addCardTechnical(response, productsList);
            });

        const href = new URL(window.location.href);
        let pageValue = href.searchParams.set('page', 1);
        history.replaceState({}, '', href.search.toString());

        productsList.addEventListener('scroll', getNewPage)

        // Нажатие на саму сцену
        technicalScene.forEach((scene) => {
            scene.addEventListener('click', (event) => {
                if (event.target.classList.contains('technical-section__main-scene')) {
                    const addProductDisabledBtn = productsList.querySelector('.add_new_product_disabled');
                    const addProductBtns = productsList.querySelectorAll('.add_new_product');
                    const arrScene = Array.from(scene.children);
                    // Вычисление координат точки нажатия
                    const sceneSizes = scene.getBoundingClientRect();
                    const xPercent = ((event.clientX - sceneSizes.left) / sceneSizes.width) * 100;
                    const yPercent = ((event.clientY - sceneSizes.top) / sceneSizes.height) * 100;

                    // Создание дива, и вычисление его координат в процентах
                    const placeMark = document.createElement('div');
                    placeMark.style.top = yPercent + '%';
                    placeMark.style.left = xPercent + '%';

                    // Проверка на сценарий

                    if (addProductDisabledBtn) {
                        // Если есть выделенная карточка
                        // Второй сценарий

                        const contextualMenu = document.getElementById('contextual_menu');
                        contextualMenu.style.display = 'none'; // Закрываем контекстное меню
                        const card = addProductDisabledBtn.parentElement; // Получение карточки
                        // Фунцкии создания карточки и вычисления её положения
                        appendCard(placeMark, card);
                        checkValueGetState(xPercent, 'directionX', placeMark);
                        checkValueGetState(yPercent, 'directionY', placeMark);

                        // Убираем класс неактивной кнопки, и превращаем в обычную
                        addProductDisabledBtn.classList.remove('add_new_product_disabled');
                        addProductDisabledBtn.classList.add('active');

                        scene.appendChild(placeMark); // Добавляем карточку в сцену
                        scene.style.pointerEvents = 'auto'; // Возвращаем сцене pointer-events для добавления новых точек

                    } else {
                        // Если нет выделенной карточки
                        // Первый сценарий
                        addProductBtns.forEach((el) => {
                            el.classList.remove('active');
                        });

                        const allPlaceMarks = document.querySelectorAll('.placemark_setted');
                        allPlaceMarks.forEach((el) => {
                            el.classList.remove('active');
                        });
                        // Создаём новую точку и вычисляем её координаты в процентах
                        placeMark.classList.add('placemark_new');
                        placeMark.innerHTML = `
                            <div class="button button_icon_large">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.9974 6.66663V25.3333M6.66406 16H25.3307" stroke="#EE7F01" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div class="placemark_new__choose-product">
                                <div class="placemark__choose-product__title">
                                    Выберите товар из списка справа
                                </div>
                                <button class="button button_text_small decline-placemark">
                                    Отменить выделение
                                </button>
                            </div>
                        `
                        checkValueGetState(xPercent, 'directionX', placeMark);
                        checkValueGetState(yPercent, 'directionY', placeMark);
                        scene.appendChild(placeMark);  // Добавляем новую точку в сцену
                        scene.style.pointerEvents = 'none'; // Отключаем pointer-events, чтобы не ставили много точек
                    }
                }

                // Первый сценарий
                // Удаление новой точки
                const declineBtn = scene.querySelector('.decline-placemark');
                if (declineBtn) {
                    declineBtn.addEventListener('click', () => {
                        changeBtnsStates()
                        const placeMarkDiv = scene.querySelector('.placemark_new');
                        scene.removeChild(placeMarkDiv);
                        scene.style.pointerEvents = 'auto';
                    })
                }
                validateToSave()
            });
        });


        // Сохранение изменений

        const saveChangesBtn = document.getElementById('save_changes_btn');

        saveChangesBtn.addEventListener('click', () => {
            let dataJson = [];
            technicalScene.forEach((scene) => {
                const newPlacemark = document.querySelector('.placemark_new');
                if(newPlacemark) {
                    newPlacemark.parentElement.removeChild(newPlacemark);
                };
                const sceneId = scene.getAttribute('data-id');
                let products = [];
                Array.from(scene.children).forEach((el) => {
                    let productData = {};
                    let coordinate = {};
                    const id = el.getAttribute('data-id');
                    const top = el.style.top.replace(/%/g, '');
                    const left = el.style.left.replace(/%/g, '');
                    productData['product'] = id;
                    coordinate['top'] = Number(top);
                    coordinate['left'] = Number(left);
                    productData.coordinate = (coordinate)
                    products.push(productData);

                });
                let sceneInfo = {
                    photo: sceneId,
                    products: products
                }
                dataJson.push(sceneInfo)
            });
            // let data = JSON.stringify(dataJson)
            sendForm(dataJson, '/api/ready-solution/add/');
            window.location.reload();
        });

    }
    ;

    const copyCurrLink = document.querySelector('.copy_current_link');
    if (copyCurrLink) {
        copyCurrLink.addEventListener('click', () => {
            copyCurrentUrl()
        });
    }
    ;

}


async function sendForm(data, url) {
    const csrfToken = $('[name=csrfmiddlewaretoken]').val();
    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': "application/json",
            'X-CSRFToken': csrfToken
        },
        body: JSON.stringify(data),
    });
    // if(response.ok) return response.json();
    // else throw new Error(response.statusText);
};

async function getForm(url) {
    const csrfToken = $('[name=csrfmiddlewaretoken]').val();
    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Content-Type': "application/json",
            'X-CSRFToken': csrfToken
        },
    });
    if(response.ok) return response.json();
    else throw new Error(response.statusText);
};

export function changeBtnsStates() {
    const technicalScenes = document.querySelectorAll('.technical-section__main-scene');
    const newProductBtns = document.querySelectorAll('.add_new_product');
    technicalScenes.forEach((scene) => {
        const arrScene = Array.from(scene.children);
        arrScene.forEach((el) => {
            const attr = el.getAttribute('data-id');
            newProductBtns.forEach((btn) => {
                const btnAttr = btn.getAttribute('data-id');
                if (attr == btnAttr) {
                    btn.classList.add('active');
                }
                ;
            });
        });
    });
};

// Вычисление положения для добавления соответсвующих классов
export function checkValueGetState(value, direction, placeMark) {
    if (value > 50 && direction == 'directionX') {
        placeMark.classList.add('right');
    }
    if (value > 50 && direction == 'directionY') {
        placeMark.classList.add('bottom');
    }
};

// Добавление карточки
// placeMark = Созданный див
// elem = Карточка, с которой собираем инфу
export function appendCard(placeMark, elem) {

    const idBtn = elem.querySelector('.add_new_product').getAttribute('data-id');
    const cardTitle = elem.querySelector('.product-card_technical__title-block__title').innerText;
    const cardBasicPrice = elem.querySelector('.price_basic');
    const cardPrice = elem.querySelector('.price').value;
    const cardImg = elem.querySelector('img').src;

    let cardBasicPriceVal = '';
    if (cardBasicPrice) {
        cardBasicPriceVal = cardBasicPrice.value + ' ₽';
    }
    placeMark.classList.remove('placemark_new')
    placeMark.classList.add('placemark_setted');
    placeMark.setAttribute("data-id", idBtn);
    placeMark.innerHTML = `
        <div class="placemark_setted__btn">
        </div>
        <a class="product-card product-card_size_micro">
            <div class="product-card_size_micro__img">
                <img loading="lazy" src="${cardImg}">
            </div>
            <div class="product-card_size_micro__title-block">
                <div class="product-card_size_micro__cost-block">
                    <div class="product-card_size_micro__cost-block__cost">
                        ${cardPrice} ₽
                    </div>
                    <div class="product-card_size_micro__cost-block__cost_prev">
                        ${cardBasicPriceVal} 
                    </div>
                </div>
                <div class="product-card_size_micro__title">
                    ${cardTitle}
                </div>
                <button class="button button_text_small delete-placemark">
                    Удалить
                </button>
            </div>
        </a>
    `

    const settedBtn = placeMark.querySelector('.placemark_setted__btn');
    const deleteBtn = placeMark.querySelector('.delete-placemark');
    settedBtn.addEventListener('click', () => {
        (placeMark.classList.contains('active')) ? placeMark.classList.remove('active') : placeMark.classList.add('active');
    });
    deleteBtn.addEventListener('click', () => {
        if (placeMark.classList.contains('placemark_setted')) {
            deleteCard(deleteBtn, placeMark);
        };
    });
};

// Удаление карточек в зависимости от нажатой кнопки
export function deleteCard(pressedButton, pressedCard) {
    const technicalScene = document.querySelectorAll('.technical-section__main-scene');
    if (pressedButton.classList.contains('add_new_product')) {
        // Если кнопка вне сцены
        const btnId = pressedButton.getAttribute('data-id');
        // То получаем установленные карточки в сцене
        const settedCards = document.querySelectorAll('.placemark_setted');
        settedCards.forEach((card) => {
            // Итерируемся по ним и получаем айди каждой
            const cardId = card.getAttribute('data-id');
            if (cardId == btnId) {
                // Если айди активной кнопки и карточки равно в текущем слайде то удаляем
                if (card.parentElement.classList.contains('swiper-slide-active')) {
                    card.parentElement.removeChild(card);
                    let flag = false;
                    technicalScene.forEach((scene) => {
                        const arrScene = Array.from(scene.children);
                        if (arrScene.length > 0) {
                            arrScene.forEach((el) => {
                                if (el.getAttribute('data-id') == btnId) {
                                    flag = true;
                                }
                            })
                        } else {
                            pressedButton.classList.remove('active');
                        }
                    });
                    if (flag) {
                        pressedButton.classList.add('active');
                    } else {
                        pressedButton.classList.remove('active');
                    }
                }
            }
            ;
        })
    } else if (pressedButton.classList.contains('delete-placemark')) {
        // Если кнопка с карточке сцены
        const cardId = pressedCard.getAttribute('data-id');
        const scene = pressedCard.parentElement;
        // Получаем все кнопки вне
        const buttons = document.querySelectorAll('.add_new_product');
        buttons.forEach((btn) => {
            // Итерируемся по ним и получаем айди каждой
            const btnId = btn.getAttribute('data-id');
            if (cardId == btnId) {
                // Если айди активной кнопки и карточки равно то удаляем
                if (scene.classList.contains('swiper-slide-active')) {
                    scene.removeChild(pressedCard);
                    let flag = false;
                    technicalScene.forEach((scene) => {
                        const arrScene = Array.from(scene.children);
                        if (arrScene.length > 0) {
                            arrScene.forEach((el) => {
                                if (el.getAttribute('data-id') == cardId) {
                                    flag = true;
                                }
                            })
                        } else {
                            btn.classList.remove('active');
                        }
                    });
                    if (flag) {
                        btn.classList.add('active');
                    } else {
                        btn.classList.remove('active');
                    }
                }
            }
            ;
        })
    }
};

export function contextualMenu(btn) {
    const btnCoords = btn.getBoundingClientRect();
    const contextualMenu = document.getElementById('contextual_menu');
    const contextualMenuBtn = contextualMenu.querySelector('.delete_new_product');
    contextualMenu.style.top = btnCoords.top + 'px';
    contextualMenu.style.left = btnCoords.left + 'px';
    contextualMenu.style.display = 'flex';
    contextualMenuBtn.addEventListener('click', () => {
        btn.classList.remove('add_new_product_disabled');
        contextualMenu.style.display = 'none';
    })
};

export function validateToSave() {
    const saveChangesBtn = document.getElementById('save_changes_btn');
    const allPlaceMarksSetted = document.querySelectorAll('.placemark_setted');
    if (allPlaceMarksSetted.length > 0) {
        saveChangesBtn.classList.remove('button_accent_disabled');
    } else {
        saveChangesBtn.classList.add('button_accent_disabled');
    }
};

function copyCurrentUrl() {
    const currentUrl = window.location.href;
    const tempInput = document.createElement('input');
    tempInput.value = currentUrl;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    alert('Текущая ссылка скопирована в буфер обмена');
};


const debouncedSetNewPage = debounce(setNewPage, 300)

const debouncedAddCard = debounce(addCardTechnical, 300);

function getNewPage() {
    const productsList = document.querySelector('.technical-section__products-block__list');
    const parentRect = productsList.parentNode.getBoundingClientRect();
    const productCard = productsList.querySelector('.product-card:last-of-type');
    const productRect = productCard.getBoundingClientRect();

    const href = new URL(window.location.href);
    let pageValue = href.searchParams.get('page');

    if(productRect.bottom > parentRect.bottom + getRem() * 7) {
        getForm(`/api/technical-products/?page=${pageValue}`)
        .then(response=>{
            if(!response.next) productsList.removeEventListener('scroll', getNewPage);
            debouncedSetNewPage();
            debouncedAddCard(response, productsList);
        });
    }
    
}
function setNewPage() {
    const href = new URL(window.location.href);
    let pageValue = href.searchParams.get('page');
    if(pageValue !== null) {
        pageValue = parseInt(pageValue) + 1;
        href.searchParams.set('page', pageValue.toString());
    } else {
        href.searchParams.set('page', 2);
    }
    history.replaceState({}, '', href.search.toString());
}

function getRem() {
    var element = document.querySelector("html");
    var fontSize = window.getComputedStyle(element).fontSize;
    var numericFontSize = parseInt(fontSize, 10);
    return fontSize.slice(0, -2)
}